import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const options = {
    loop: true,
    nav: true,
    margin: 10,
    items: 1,
    navContainer: false,
    navElement: "div",
    dots: true,
};


const RoomGrid = ({ rooms, loading, currentPage }) => {

    const generateRandomRating = () => {
        const min = 4.5;
        const max = 5.0;
        const step = 0.1;
        const randomValue = Math.round((Math.random() * (max - min) + min) / step) * step;
        return randomValue.toFixed(1); // Ensuring 1 decimal place
    };

    return (
        <>
            <div className='room_card_wrapper'>
                {rooms && rooms.length > 0 ? (
                    rooms.map((room, index) => (
                        <>
                            <div className="room_card_single" key={room.id}>
                                <div className='room-card card border-0'>
                                    <div className='card-img-top'>
                                        <OwlCarousel className="room_card_image_slider owl-carousel owl-theme" {...options} key={`room_image_carousel-${room.id}`}>
                                            <div className="item" key={`feature-${room.id}`}>
                                                <Link to={`/room/${room.id}/details`}>
                                                    <LazyLoadImage
                                                        alt={room.title}
                                                        className=""
                                                        effect="blur"
                                                        src={room.featured_image[0].data_url}
                                                        width="100%"
                                                        style={{}}
                                                    />
                                                </Link>
                                            </div>
                                            {room.images && room.images.length > 0 && room.images.map((image, index) => (
                                                <div key={`image-${room.id}-${index}`} className="item">
                                                    <Link to={`/room/${room.id}/details`}>
                                                        <LazyLoadImage
                                                            alt={room.title}
                                                            effect="blur"
                                                            src={image.data_url}
                                                            width="100%"
                                                            style={{}}
                                                        />
                                                    </Link>
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    </div>
                                    <div className="card-body room_card_details p-0">
                                        <Link to={`/room/${room.id}/details`}>
                                            <div className='room_title'>
                                                <span>{room.title}</span>
                                                <span className='room_rating'><i className="fa fa-star"></i> {generateRandomRating()}</span>
                                            </div>
                                            <p className='text-muted room_address'>{room.apartment.address}</p>
                                            <p className='room_price m-0'>
                                                {room.price_per_months ? (
                                                    <strong className="text-decoration-underline">৳{room.price_per_months}</strong>
                                                ) : (
                                                    <strong className="text-decoration-underline">৳{room.price_per_night}</strong>
                                                )}{" "}
                                                {room.price_per_months ? "per month" : "per night"}
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))

                ) : (
                    <div className="alert alert-primary" role="alert">Sorry! no rooms found!.</div>
                )}

                {loading && currentPage !== 1 && (
                    <>
                        {Array.from({ length: 6 }).map((_, index) => (
                            <div className="room_card_single" key={`loading-${index}`}>
                                <div className="ph-item p-0 border-0">
                                    <div className="ph-col-12 p-0">
                                        <div className="ph-picture"></div>
                                        <div className="ph-row">
                                            <div className="ph-col-6"></div>
                                            <div className="ph-col-4 empty"></div>
                                            <div className="ph-col-2"></div>
                                            <div className="ph-col-8"></div>
                                            <div className="ph-col-4 empty"></div>
                                            <div className="ph-col-12"></div>
                                            <div className="ph-col-6"></div>
                                            <div className="ph-col-6 empty"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
}

export default RoomGrid;
