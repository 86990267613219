import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import SocialLogin from "./SocialLogin";
import api from "../../../_utility/AxiosConfig";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = ({ closeModal, setModalMode }) => {
    const [formData, setFormData] = useState({
        email: "",
        loading: false,
        error: '',
        errors: [],
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: [], error: '', loading: true });
    
        try {
            const response = await api.post('/api/forgot-password', formData);
    
            if (response.data.status === 400) {
                setFormData({
                    ...formData,
                    errors: response.data.errors,
                    error: response.data.message,
                    loading: false,
                });
                toast.error(response.data.message);
            } else if (response.data.status === 200) {
                setFormData({
                    ...formData,
                    email: "",
                    loading: false,
                    error: '',
                    errors: [],
                });
                closeModal();
                Swal.fire('Success!', response.data.message, 'success');
            }else if (response.data.status === 404) {
                setFormData({
                    ...formData,
                    loading: false,
                    error: response.data.message,
                    errors: [],
                });
                toast.error(response.data.message);
            }
        } catch (error) {
            const errorMessage =
                error.response?.data?.message || // Server provided message
                error.message ||                // Axios error message
                'An unknown error occurred.';  // Default fallback
    
            setFormData({
                ...formData,
                error: errorMessage,
                loading: false,
            });
    
            closeModal();
            Swal.fire('Error!', errorMessage, 'error');
        }
    };

    return (
        <>
        <ToastContainer />
            <Form onSubmit={handleSubmit}>
                {/* <div className="log-in-pop-left">
                    <div style={{ 'position':'relative', 'zIndex':'10' }}> 
                        <h1>Hello there</h1>
                        <p>Welcome to younic home.</p>
                        <h4 style={{'fontSize':'18px'}}>Login with social media</h4>
                        <ul className='list-unstyled'>
                            <SocialLogin setModalMode={setModalMode}/>
                        </ul>
                    </div>
                </div> */}
                <div className="log-in-pop-right w-100">
                    <a href="#" className="pop-close" onClick={closeModal}><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
                    </a>
                    <h4>Forgot password</h4>
                    <p>Don’t worry! We can help you get back on track in just a few simple steps.</p>
                    <div className='form-group'>
                        <div className="input-field">
                            <input type="text" 
                                id='email' 
                                name='email' 
                                autoComplete="" 
                                className="form-control form-control-lg" 
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder='' 
                            />
                            <label htmlFor='email' className='input-field-label'>E-mail Address</label>
                        </div>
                        {formData.errors && formData.errors.email && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.email}</strong></p>
                        )}
                        {formData.error && <Alert variant="danger" className="mt-2">{formData.error}</Alert>}
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <Button variant="success" className="btn-block" type="submit" disabled={formData.loading}>
                                {formData.loading ? "Processing..." : "Submit"}
                            </Button>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                    <p>Already a member? <a href="#" onClick={() => setModalMode("login")} className="text-success text-primary">Login here</a> | Not a member? <a href="#" className="text-success" onClick={() => setModalMode("register")}>Create a new account</a></p>
                        <p>or sign up with:</p>
                        <SocialLogin setModalMode={setModalMode} />
                    </div>
                </div>
            </Form>
        </>
    );
}


export default ForgotPassword;
