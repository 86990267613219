import React, { useState, useEffect, useRef } from 'react';
import HtmlParser from 'html-react-parser';
import WithRouter from '../../_utility/WithRouter';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BootstrapDatePicker from '../../component/partials/BootstrapDatePicker';
import api from '../../_utility/AxiosConfig';
import FAQ from '../../component/common/Faq';
import CustomerReview from '../../component/partials/CustomerReview';


const RoomDetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [room, setRoom] = useState([]);
    const [images, setImages] = useState([]);
    const [description, setDescription] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        const room_id = props.params.room_id;

            api.get(`/api/room/${room_id}/details`)
                .then(res => {
                    const data = res.data.data;
                    setRoom(data);
                    setImages(data.images);
                    setDescription(data.description);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching rooms:", error);
                });
    }, [props.params.room_id]);

    const [reserve, setReserve] = useState({
        occupancy_type: '',
        total_adults: 1,
        total_children: 0,
        number_of_seats: 1,
        dateRange: '',
        date: '',
        isOverlayOpen: false,
        error: '',
    });

    const pickerRef = useRef(null);
    const overlayRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const navigate = useNavigate();

    const OccupancyOptions = {
        Both: {
            "": "Occupancy Type",
            Permanent: "Permanent",
            Guest: "Guest",
        },
        Permanent: {
            "": "Occupancy Type",
            Permanent: "Permanent",
        },
        Guest: {
            "": "Occupancy Type",
            Guest: "Guest",
        },
    };

    const increment = (key) => {
        if (key === 'total_adults') {
            setReserve((prev) => ({
                ...prev,
                total_adults: prev['total_adults'] + 1,
                number_of_seats: prev['total_adults'] + 1,
            }));
        } else {
            setReserve((prev) => ({
                ...prev,
                [key]: prev[key] + 1,
            }));
        }
    };

    const decrement = (key) => {
        if (key === 'number_of_seats') {
            setReserve((prev) => ({
                ...prev,
                [key]: prev[key] > prev['total_adults'] ? prev[key] - 1 : prev['total_adults'],
            }));
        } else {
            setReserve((prev) => ({
                ...prev,
                [key]: prev[key] > 0 ? prev[key] - 1 : 0,
            }));
        }
    };

    const updateState = (key, value) => {
        setReserve((prevState) => ({
            ...prevState,
            [key]: value,
        }));

        if (key === 'occupancy_type' && value) {
            setReserve((prev) => ({
                ...prev,
                date: '',
                dateRange: '',
                error: '',
            }));
        }
        if (key === 'date' && value && reserve.occupancy_type === 'Permanent') {
            setReserve((prev) => ({
                ...prev,
                error: '',
            }));
        }
        if (key === 'dateRange' && value && reserve.occupancy_type !== 'Permanent') {
            setReserve((prev) => ({
                ...prev,
                error: '',
            }));
        }
    };


    const toggleOverlay = () => {
        setReserve((prev) => ({
            ...prev,
            isOverlayOpen: !prev.isOverlayOpen,
        }));
    };

    const handleClickOutside = (event) => {
        if (
            overlayRef.current &&
            !overlayRef.current.contains(event.target) &&
            !toggleButtonRef.current.contains(event.target)
        ) {
            setReserve((prev) => ({
                ...prev,
                isOverlayOpen: false,
            }));
        }
    };

    useEffect(() => {
        if (reserve.isOverlayOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [reserve.isOverlayOpen]);

    const handleReserveClick = () => {
        const room_id = props.params.room_id;
        updateState('error', '');

        if (!reserve.occupancy_type) {
            updateState('error', 'Please select an occupancy type.');
            return;
        }
        if (reserve.occupancy_type === 'Permanent' && !reserve.date) {
            updateState('error', 'Please select a check-in date.');
            return;
        }
        if (reserve.occupancy_type !== 'Permanent' && (!reserve.dateRange[0] || !reserve.dateRange[1])) {
            updateState('error', 'Please select both check-in and check-out dates.');
            return;
        }

        navigate(`/room/${room_id}/booking`, {
            state: {
                branch_id: room?.apartment?.branch_id || '',
                apartment_id: room?.apartment?.id || '',
                room_type: room?.room_type || '',
                occupancy_type: reserve.occupancy_type,
                total_adults: reserve.total_adults,
                total_children: reserve.total_children,
                number_of_seats: reserve.number_of_seats,
                date: reserve.date,
                dateRange: reserve.dateRange,
            },
        });
    };

    const handleDateApply = (date) => {
        setReserve((bookingInfo) => ({
            ...bookingInfo,
            dateRange: '',
            date: date
        }));
    };

    const handleDateRangeSelect = ({ start, end }) => {
        setReserve((bookingInfo) => ({
            ...bookingInfo,
            date: '',
            dateRange: [start, end]
        }));
    };

    return (
        <>
            <section>
                <div className="container-fluid pad-bot-40 mt-5">
                    <div className="col-sm-11 mx-auto">
                        <div className="to-ho-hotel">
                            <div className="row">
                                <div className='col-sm-12'>
                                    <div className="modal fade" id="galeryModal" tabindex="-1" role="dialog" aria-labelledby="galeryModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="galeryModalLabel">All Photos</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    {room.featured_image && room.featured_image.length > 0 ? (
                                                        <img
                                                            src={room.featured_image[0].data_url}
                                                            alt={room.title}
                                                            className="img-fluid"
                                                        />
                                                    ) : (<></>)}
                                                    {images && images.length > 0 ? (
                                                        images.map((image, index) => (
                                                            <img
                                                                src={image.data_url}
                                                                alt={room.title}
                                                                className="img-fluid"
                                                            />
                                                        ))
                                                    ) : (<></>)}
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {room.featured_image && room.featured_image.length > 0 ? (
                                        <div className="row gallery_images_wrap">
                                            <div className="col-md-6 room_gallery_large p-0">
                                                {room.featured_image && room.featured_image.length > 0 ? (
                                                    <img
                                                        src={room.featured_image[0].data_url}
                                                        alt={room.title}
                                                        className='d-none d-lg-block d-md-block'
                                                        loading='lazy'
                                                    />
                                                ) : (
                                                    <>
                                                        <img src="/assets/images/room/4.jpg" className='d-none d-lg-block d-md-block' alt="Gallery 2" />
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-6 room_gallery_small p-0">
                                                {images && images.length > 0 ? (
                                                    images.map((image, index) => (
                                                        <img
                                                            src={image.data_url}
                                                            alt={room.title}
                                                            loading='lazy'
                                                        />
                                                    ))
                                                ) : (
                                                    <>
                                                        <img src="/assets/images/room/4.jpg" alt={room.title} />
                                                        <img src="/assets/images/room/4.jpg" alt={room.title} />
                                                        <img src="/assets/images/room/4.jpg" alt={room.title} />
                                                        <img src="/assets/images/room/4.jpg" alt={room.title} />
                                                    </>
                                                )}
                                                <a href='javascripts:void' className='btn btn-light border border-dark gallery-grid-btn' data-toggle="modal" data-target="#galeryModal"><i className="fa fa-th" aria-hidden="true"></i> Show all photos</a>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row gallery_images_wrap">
                                                <div className="col-md-6 room_gallery_large p-0">
                                                    <div className="ph-item p-0 border-0">
                                                        <div className="ph-picture" style={{ 'height': '470px' }}></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 room_gallery_small p-0">
                                                    <div className="ph-item p-0 border-0" style={{ 'display': 'grid', 'grid-template-columns': 'repeat(2, 1fr)', 'gap': '10px' }}>
                                                        <div className="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                        <div className="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                        <div className="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                        <div className="ph-picture mb-0" style={{ 'height': '230px' }}></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )}

                                </div>
                            </div>

                            {room && room.title ? (
                                <>
                                    <div className="hom-com container-lg pt-5 pb-1">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-8">
                                                <div className='form-group mb-5'>
                                                    <h2>{room.title}</h2>
                                                    <span>{room.room_type} . {room.max_occupancy} Seat . Free Internet</span>
                                                </div>

                                                <CustomerReview/>
                                                
                                                <div className="hp-section">
                                                    <div className="hp-amini detai-2p">
                                                        {HtmlParser(description)}
                                                    </div>
                                                </div>
                                                <div className="hp-section mb-3">
                                                    <div className="hp-sub-tit">
                                                        <h4><span>Aminitiese</span> Room</h4>
                                                        <p>A well-equipped space designed for relaxation and convenience, offering essential facilities to enhance comfort and productivity.</p>
                                                    </div>
                                                    <div className="room-amini overflow-hidden">
                                                        <ul className='overflow-hidden p-0'>
                                                            {room.amenities && room.amenities.length > 0 ? (
                                                                room.amenities.map((amenity, index) => (
                                                                    <li key={index}>
                                                                        <img
                                                                            src={amenity.thumbnail || "/assets/images/icon/a2.png"}
                                                                            alt={amenity.name}
                                                                        />
                                                                        {amenity.name}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-4">
                                                <div className="sidebar_widget">
                                                    <div className='sidebar_booking_widget'>
                                                        <p className='room_price'>
                                                            {reserve.occupancy_type === 'Permanent' ? (
                                                                <>
                                                                    {room.price_per_month ? (
                                                                        <>
                                                                            <strong>৳{room.price_per_month}</strong>
                                                                            <span className='text-muted'> per month</span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {room.price_per_night ? (
                                                                        <>
                                                                            <strong>৳{room.price_per_night}</strong>
                                                                            <span className='text-muted'> per night</span>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                        <div className='booking_widget_form mb-3'>
                                                            <div className="form-group mb-0 border-bottom">
                                                                <select
                                                                    name="occupancy_type"
                                                                    className="form-control form-control-lg"
                                                                    style={{ "border-radius": "10px 10px 0 0", "border": "none", "font-size": "16px", "color": "#111", "font-weight": "500", "height": "48px" }}
                                                                    onChange={(e) => updateState('occupancy_type', e.target.value)}
                                                                >   {OccupancyOptions[room.occupancy_type] &&
                                                                    Object.entries(OccupancyOptions[room.occupancy_type]).map(([value, label]) => (
                                                                        <option key={value} value={value}>
                                                                            {label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="dates" ref={pickerRef}>
                                                                {reserve.occupancy_type === 'Permanent' ? (
                                                                    <>
                                                                        <BootstrapDatePicker
                                                                            key="permanent"
                                                                            onDateSelect={handleDateApply}
                                                                            isRange={false}
                                                                            initialDate={reserve.date}
                                                                            placeholderText={'Check In'}
                                                                            initialSettings={{
                                                                                minDate: moment().startOf('day'),
                                                                            }}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <BootstrapDatePicker
                                                                            key="guest"
                                                                            onDateSelect={handleDateRangeSelect}
                                                                            isRange={true}
                                                                            initialDate={reserve.dateRange}
                                                                            placeholderText={'Check In ~ Check Out'}
                                                                            initialSettings={{
                                                                                minDate: moment().startOf('day'),
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className='guest'>
                                                                <div ref={toggleButtonRef} onClick={toggleOverlay}>
                                                                    <p><strong>{reserve.occupancy_type === 'Permanent' ? "Seat" : "Guest"}</strong></p>
                                                                    <p>
                                                                        {reserve.occupancy_type === 'Permanent' ? (
                                                                            <>
                                                                                {reserve.number_of_seats > 0 && <span>{reserve.number_of_seats} Seat{reserve.number_of_seats > 1 ? 's' : ''} </span>}
                                                                                {(reserve.number_of_seats === 0) && (
                                                                                    <span>Add Seat</span>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {reserve.total_adults > 0 && <span>{reserve.total_adults} Adult{reserve.total_adults > 1 ? 's' : ''} </span>}
                                                                                {reserve.total_children > 0 && <span>{reserve.total_children} Child{reserve.total_children > 1 ? 'ren' : ''} </span>}
                                                                                {reserve.number_of_seats > 0 && <span>{reserve.number_of_seats} Seat{reserve.number_of_seats > 1 ? 's' : ''} </span>}

                                                                                {(reserve.total_adults === 0 && reserve.total_children === 0 && reserve.number_of_seats === 0) && (
                                                                                    <span>Add Guest</span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div ref={overlayRef} className={`input_overlay ${reserve.isOverlayOpen ? 'open' : ''}`}>
                                                                    {reserve.occupancy_type === 'Permanent' ? (
                                                                        <>
                                                                            <div className='guest_counter mb-0'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Seat</strong>
                                                                                    <br />
                                                                                    <span>Minimum 1+</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('number_of_seats')}>-</button>
                                                                                    <strong className='counter'>{reserve.number_of_seats}</strong>
                                                                                    <button onClick={() => increment('number_of_seats')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className='guest_counter'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Adults</strong>
                                                                                    <br />
                                                                                    <span>Age 13+</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('total_adults')}>-</button>
                                                                                    <strong className='counter'>{reserve.total_adults}</strong>
                                                                                    <button onClick={() => increment('total_adults')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='guest_counter'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Children</strong>
                                                                                    <br />
                                                                                    <span>Age 0-5</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('total_children')}>-</button>
                                                                                    <strong className='counter'>{reserve.total_children}</strong>
                                                                                    <button onClick={() => increment('total_children')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='guest_counter mb-0'>
                                                                                <div className='pull-left'>
                                                                                    <strong>Seat</strong>
                                                                                    <br />
                                                                                    <span>Minimum 1+</span>
                                                                                </div>
                                                                                <div className='input_counter pull-right'>
                                                                                    <button onClick={() => decrement('number_of_seats')}>-</button>
                                                                                    <strong className='counter'>{reserve.number_of_seats}</strong>
                                                                                    <button onClick={() => increment('number_of_seats')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {reserve.error && <p className="text-danger text-center">{reserve.error}</p>}
                                                        <button type='submit' className='btn btn-lg btn-block btn-success mb-4' onClick={handleReserveClick}>Reserve</button>

                                                        <p className='text-center mb-0'>You won't be charged yet</p>
                                                    </div>
                                                </div>

                                                <div className="sidebar_widget">
                                                    <div className="hp-call-in text-center">
                                                        <img src="/younic-logo.png" alt="" />
                                                        <h3><span>Check Availability. Call us!</span></h3>
                                                        <strong className='mb-0'>+88 01325-05 19 15</strong> <br />
                                                        <strong>+88 01711-70 47 29</strong> <br /><br />
                                                        <small>We are available 24/7 Monday to Sunday</small>
                                                        <a href="#">Call Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="hom-com container-lg pt-5 pb-1">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className='form-group mb-0'>
                                                    <div className="ph-item p-0 border-0">
                                                        <div className="ph-col-12 p-0">
                                                            <div className="ph-row">
                                                                <div className="ph-col-12 big"></div>
                                                                <div className="ph-col-6"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ph-item border-0 p-0">
                                                    <div className="ph-col-2">
                                                        <div className="ph-avatar"></div>
                                                    </div>
                                                    <div>
                                                        <div className="ph-row">
                                                            <div className="ph-col-6 big"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-2"></div>
                                                            <div className="ph-col-10 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ph-item border-0 p-0">
                                                    <div className="ph-col-12">
                                                        <div className="ph-picture"></div>
                                                        <div className="ph-row">
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>

                                                    <div className="ph-col-4">
                                                        <div className="ph-picture"></div>
                                                    </div>

                                                    <div>
                                                        <div className="ph-row">
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-2"></div>
                                                            <div className="ph-col-10 empty"></div>
                                                            <div className="ph-col-8"></div>
                                                            <div className="ph-col-4 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-12">
                                                        <div className="ph-row">
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ph-item border-0 p-0">
                                                    <div className="ph-col-12">
                                                        <div className="ph-row">
                                                            <div className="ph-col-6 big"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ph-item border-0 p-0">
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-3">
                                                        <div className="ph-avatar" style={{ 'width': '80px', 'margin': '0 auto' }}></div>
                                                        <div className="ph-row mt-2">
                                                            <div className="ph-col-6" style={{ 'width': '60px', 'margin': '0 auto' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="sidebar_widget">
                                                    <div className="ph-item border-0 p-0 m-0">
                                                        <div className="ph-col-12 p-0 m-0">
                                                            <div className="ph-row">
                                                                <div className="ph-col-6 big"></div>
                                                                <div className="ph-col-6 big"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sidebar_widget">
                                                    <div className="ph-item border-0 p-0 m-0">
                                                        <div className="ph-col-12 p-0 m-0">
                                                            <div className="ph-avatar" style={{ 'width': '100px', 'margin': '0 auto' }}></div>
                                                            <div className="ph-row mt-3">
                                                                <div className="ph-col-6 big"></div>
                                                                <div className="ph-col-6 big"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                                <div className="ph-col-12"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid pad-bot-40'>
                    <div className='row'>
                        <div className="col-sm-11 col-xxl-8 mx-auto pl-5 pr-5">
                            <div className="contact-map" style={{ 'height': '500px' }}>
                                <iframe title='Younic Home' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7303.948859349035!2d90.3701136949866!3d23.748291235068937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf00724da8b9%3A0x82cd207efebe07ac!2sYounic%20Home!5e0!3m2!1sen!2sbd!4v1731401256947!5m2!1sen!2sbd" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq_section">
                <div className="container">
                    <FAQ/>
                </div>
            </section>

        </>
    );
}

export default WithRouter(RoomDetails);
