import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import Invoice from './protected/Invoice';

const PrivacyPolicy = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <section>
            <div className="inn-body-section">
                <div className="container">
                    <div className="page-head">
                        <h2>Privacy Policy</h2>
                        <div className="head-title">
                            <div className="hl-1"></div>
                            <div className="hl-2"></div>
                            <div className="hl-3"></div>
                        </div>
                    </div>

                    <div className="content-section">
                    <div class="container mt-5">
                            <section class="mb-4">
                                <h2>Administration & Management Policy</h2>
                                <ul>
                                    <li>The overall management responsibility of the hostel lies with the hostel manager/administrator.</li>
                                    <li>All residents must adhere to these rules and regulations.</li>
                                    <li>If any resident forms groups to disrupt the hostel environment, they will be warned, and if necessary, expelled.</li>
                                    <li>Any complaints or requests related to the hostel must be submitted in writing to the hostel manager.</li>
                                    <li>The management reserves the right to inspect any resident’s room if necessary.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Rent & Financial Policy</h2>
                                <ul>
                                    <li>The rent must be paid within the stipulated due date each month.</li>
                                    <li>If payment is delayed, a fine of BDT 50 per day will be added.</li>
                                    <li>If a resident checks out without providing a 60-day notice, the security deposit will not be refunded.</li>
                                    <li>Failure to pay rent within the due date may result in seat cancellation.</li>
                                    <li>Electricity bills will depend on usage and will be calculated based on a fixed rate.</li>
                                    <li>Gas, water, and internet bills are included in the monthly rent.</li>
                                    <li>The hostel management reserves the right to revise rent and other charges, with prior notice to residents.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Security & Entry Policy</h2>
                                <ul>
                                    <li>Residents must use the designated entrance, and the main gate will be closed after 10:00 PM.</li>
                                    <li>Residents must obtain permission from the management if they need to enter after the stipulated time.</li>
                                    <li>Residents are responsible for securing their valuables; the management will not be responsible for any loss or theft.</li>
                                    <li>CCTV cameras will monitor the main entrance and other key areas for security purposes.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Guest & Visitor Policy</h2>
                                <ul>
                                    <li>Residents may bring guests for a limited period with prior permission.</li>
                                    <li>If a guest wishes to stay overnight, they must pay the designated fee and obtain approval.</li>
                                    <li>If a resident keeps an unauthorized guest, they will be fined or expelled.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Rules & Discipline</h2>
                                <ul>
                                    <li>Drugs, smoking, gambling, and any illegal substances are strictly prohibited.</li>
                                    <li>Political activities and any form of religious or racial hate speech are strictly prohibited.</li>
                                    <li>Any form of harassment toward another resident will result in immediate expulsion.</li>
                                    <li>If a resident is involved in a serious offense, they must vacate the hostel within 2 hours.</li>
                                    <li>All residents must ensure peaceful coexistence.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Emergency & Medical Policy</h2>
                                <ul>
                                    <li>Emergency exit plans will be in place in case of natural disasters or fire.</li>
                                    <li>If a resident falls seriously ill, the management will inform their family and assist in hospitalization, but the resident must bear the medical costs.</li>
                                    <li>Basic first-aid facilities will be available for residents.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Maintenance & Repair Policy</h2>
                                <ul>
                                    <li>Residents are responsible for keeping their rooms and common areas clean.</li>
                                    <li>Any damage to furniture or equipment must be compensated by the resident.</li>
                                    <li>For repairs, residents must fill out a complaint form and submit it to the management.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Seat Cancellation & Check-Out Policy</h2>
                                <ul>
                                    <li>Residents must provide a 60-day notice before leaving the hostel.</li>
                                    <li>All pending dues must be cleared.</li>
                                    <li>Before vacating, a room inspection will be conducted, and any damages must be compensated by the resident.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Legal & Ethical Policy</h2>
                                <ul>
                                    <li>Any illegal activities, such as drug use, smoking, gambling, or criminal offenses, are strictly prohibited.</li>
                                    <li>Political activities are not allowed inside the hostel.</li>
                                    <li>If any resident harms another resident, legal action will be taken.</li>
                                </ul>
                            </section>

                            <section class="mb-4">
                                <h2>Special Guidelines</h2>
                                <ul>
                                    <li>The hostel management reserves the right to modify these terms at any time.</li>
                                    <li>Regular meetings will be held to inform residents about hostel rules.</li>
                                    <li>All complaints must be submitted in writing.</li>
                                </ul>
                            </section>

                            <div class="mt-5 text-center">
                                <h2>Final Statement:</h2>
                                <p className='mb-0'>These privacy policy are mandatory for all residents.</p>
                                <p className='mb-0'>The hostel management reserves the right to amend the rules at any time.</p>
                                <p>Following these policies will help ensure a well-disciplined, secure, and resident-friendly hostel environment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicy;
