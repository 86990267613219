import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router";
import api from "../../_utility/AxiosConfig";

/* @ts-ignore */
const Invoice = () => {
    const invoiceRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState([]);
    const { booking_id } = useParams();

    useEffect(() => {
        api.post(`/api/view/${booking_id}/booking`)
            .then(res => {
                setBooking(res); // Ensure setting the correct response data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [booking_id]);


    const handleDownloadPDF = () => {
        const input = invoiceRef.current;
        /* @ts-ignore */
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save(`Booking_${booking.data.payment.invoice_number}.pdf`);
        });
    };

    const paymentMethods = [
        /* @ts-ignore */
        ...new Set(booking.data.payment.details.map((detail) => detail.payment_method))
      ].sort().join(", ");

    return (
        <section>
            <div className="inn-body-section">
                <div className="page-content container">
                    {!loading && booking ? (
                        <>
                            <div ref={invoiceRef} id="invoiceContainer" style={{ padding: "20px", background: "white" }}>
                                {/* Invoice Content */}
                                <div className="container px-0">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div>
                                                <span className="text-600 text-110 text-blue align-middle">Younic Home</span>
                                            </div>
                                            <div className="text-grey-m2">
                                                <div className="my-1">{booking.data?.apartment?.branch?.name}</div>
                                                <div className="my-1" style={{ 'maxWidth': '250px', 'fontSize': '14px' }}>{booking.data?.apartment?.branch?.address}</div>
                                                <div className="my-1">{booking.data?.apartment?.contact_number}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 text-right">
                                            {/* <div className="my-2">{booking.data.created_at}</div> */}
                                            <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">{booking.data.occupancy_type}</div>
                                            <div className="my-2">{booking.data.payment.invoice_number}</div>
                                            {/* <div className="my-2">
                                            {booking.data.check_in_date}
                                            {booking.data.check_out_date !== '' ? ` - ${booking.data.check_out_date}` : ''}
                                        </div> */}
                                            <div className="my-2">{booking.data.created_at}</div>
                                            <div className="my-2">{booking.data.status}</div>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <table className="table table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Occupant</th>
                                                    <th scope="col">Room Type</th>
                                                    {/* <th scope="col">Room</th> */}
                                                    <th scope="col">Check In</th>
                                                    <th scope="col">Check Out</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {booking.data.occupants && booking.data.occupants.length > 0 ? (
                                                    /* @ts-ignore */
                                                    booking.data.occupants.map((occupant, index) => (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td rowSpan={occupant.childrens && occupant.childrens.length > 0 ? 2 : 1}>{occupant.name}</td>
                                                                <td>{occupant.room.room_type}</td>
                                                                {/* <td>{occupant.room.room_number}</td> */}
                                                                <td>{occupant.room.seat.check_in_date}</td>
                                                                <td>{occupant.room.seat.check_out_date !== '' ? occupant.room.seat.check_out_date : ''}</td>
                                                                <td>৳{occupant.room.seat.amount}</td>
                                                            </tr>

                                                            {occupant.childrens && occupant.childrens.length > 0 && (
                                                                <tr>
                                                                    <td colSpan={5} className="p-0">
                                                                        <table width="100%" className="table table-sm mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="border-left-0 border-right-0">Relation</th>
                                                                                    <th>Name</th>
                                                                                    <th>Gender</th>
                                                                                    <th>Identification</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {/* @ts-ignore */
                                                                                    occupant.childrens.map((child, childIndex) => (
                                                                                        <tr key={childIndex}>
                                                                                            <td className="border-left-0">{child.relation}</td>
                                                                                            <td>{child.name}</td>
                                                                                            <td>{child.gender}</td>
                                                                                            <td className="border-right-0">{child.identification_type} : {child.identification_number}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={7}>No occupant details available</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>


                                        <div className="row mt-3">
                                            <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">{booking.data.remarks}</div>
                                            <div className="col-12 col-sm-5 text-grey text-90 text-right">
                                                <div className="row my-2">
                                                    <div className="col-7 text-right">Subtotal</div>
                                                    <div className="col-5">৳{booking.data.booking_amount}</div>
                                                </div>
                                                <div className={`row my-2 ${booking.data.occupancy_type !== 'Permanent' ? 'd-none' : ''}`}>
                                                    <div className="col-7 text-right">Admission Fee</div>
                                                    <div className="col-5">৳{booking.data.net_admission_fee}</div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className="col-7 text-right">{booking.data.occupancy_type !== 'Permanent' ? 'Security Fee' : 'Advance Fee'}</div>
                                                    <div className="col-5">৳{booking.data.conditional_fee}</div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className="col-7 text-right">Net Total</div>
                                                    <div className="col-5">৳{booking.data.net_total_amount}</div>
                                                </div>
                                                <div className="row my-2">
                                                <div className="col-7 text-right">P. Method</div>
                                                    <div className="col-5">{paymentMethods}</div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className="col-7 text-right">Paid</div>
                                                    <div className="col-5">৳{booking.data.paid_amount}</div>
                                                </div>
                                                <div className="row my-2">
                                                    <div className="col-7 text-right">Due</div>
                                                    <div className="col-5 text-danger">৳{booking.data.due_amount}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <p><strong>Instructions:</strong></p>
                                        <div className="row mt-3">
                                            <ul>
                                                <li>The authority will not be responsible for any transactions without a money receipt.</li>
                                                <li>If a PAN card is lost, a fine of 500 Taka must be paid to obtain a new one.</li>
                                                <li>If the room locker key is lost, a fine of 300 Taka must be paid to get a replacement.</li>
                                                <li>Ensure that the manually issued money receipt has the necessary signature and seal (not applicable for online-generated receipts).</li>
                                                <li>Guests must collect their deposit money at the time of check-out.</li>
                                                <li>Any damage to the room during check-out must be compensated with an equivalent fine.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-blue-d2">
                                <div className="page-tools">
                                    <div className="action-buttons text-center mt-5">
                                        <button className="btn btn-primary btn-block" onClick={handleDownloadPDF}>
                                            <i className="mr-1 fa fa-download text-120 w-2"></i> Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-md-12 shadow_card">
                                    <div className="ph-item border-0 p-0 m-0">
                                        <div>
                                            <div className="ph-row">
                                                <div className="ph-col-6"></div>
                                                <div className="ph-col-6 empty"></div>
                                                <div className="ph-col-2"></div>
                                                <div className="ph-col-10 empty"></div>
                                                <div className="ph-col-8"></div>
                                                <div className="ph-col-4 empty"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                            </div>
                                        </div>
                                        <div className="ph-col-12">
                                            <div className="ph-row">
                                                <div className="ph-col-10 big"></div>
                                                <div className="ph-col-2 empty big"></div>
                                                <div className="ph-col-4"></div>
                                                <div className="ph-col-8 empty"></div>
                                                <div className="ph-col-6"></div>
                                                <div className="ph-col-6 empty"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-10 big"></div>
                                                <div className="ph-col-2 empty big"></div>
                                                <div className="ph-col-4"></div>
                                                <div className="ph-col-8 empty"></div>
                                                <div className="ph-col-6"></div>
                                                <div className="ph-col-6 empty"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-10 big"></div>
                                                <div className="ph-col-2 empty big"></div>
                                                <div className="ph-col-4"></div>
                                                <div className="ph-col-8 empty"></div>
                                                <div className="ph-col-6"></div>
                                                <div className="ph-col-6 empty"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                                <div className="ph-col-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section >
    );
};

export default Invoice;