import React, {Component} from 'react';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import Dashboard from "../pages/protected/Dashboard";
import NotFound from "../pages/NotFound";
import Footer from '../component/common/Footer';
import Header from '../component/common/Header';
import RoomDetails from '../pages/rooms/RoomDetails';
import GuestRoutes from '../_utility/GuestRoutes';
import axios from "axios";
import SessionHelper from '../session/SessionHelper';
import RoomBooking from '../pages/rooms/RoomBooking';
import SocialLoginCallback from '../component/auth/partials/SocialLoginCallback';
import AuthRoutes from '../_utility/AuthRoutes';
import BookingConfirm from '../pages/rooms/BookingConfirm';
import Profile from '../pages/protected/Profile';
import ChangePassword from '../pages/protected/ChangePassword';
import BkashCallbackPage from '../pages/Payment/BkashCallbackPage';
import View from '../pages/protected/Booking/View';
import Edit from '../pages/protected/Booking/Edit';
import ResetPassword from '../pages/Guest/ResetPassword';
import Login from '../pages/Guest/Login';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import Invoice from '../pages/protected/Invoice';

class AppRoute extends Component {
    render() {
        return (
            <>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="*" element={<NotFound/>} />
                        <Route path="/" element={<Home />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="about-us" element={<AboutUs />} />
                        <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="terms-of-service" element={<TermsAndConditions />} />
                        <Route element={<AuthRoutes/>}>
                            <Route path="/bkash-callback" element={<BkashCallbackPage />} />
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="profile" element={<Profile />} />
                            <Route path="change/password" element={<ChangePassword />} />
                            <Route path="view/:booking_id/booking" element={<View />} />
                            <Route path="invoice/:booking_id/view" element={<Invoice />} />
                            <Route path="booking/edit/:booking_id" element={<Edit />} />
                            <Route path="confirm/:booking_id/booking" element={<BookingConfirm />} />
                        </Route>
                        <Route path="/room/:room_id/details" element={<RoomDetails />} />
                        <Route path="/room/:room_id/booking" element={<RoomBooking />} />
                        <Route element={<GuestRoutes/>}>
                            <Route path="login" element={<Login />} />
                            <Route path="reset/password/:token" element={<ResetPassword />} />
                            <Route path="auth/:provider/callback" element={<SocialLoginCallback />} />
                        </Route>

                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </>
        );
    }
}

export default AppRoute;
