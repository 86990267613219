import React, { useEffect, useRef, useState } from 'react';
import FilterSection from '../component/partials/FilterSection';
import RoomGrid from '../component/partials/RoomGrid';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import api from '../_utility/AxiosConfig';

const Home = () => {
    const [rooms, setRooms] = useState([]);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const [hasMore, setHasMore] = useState(true); // Track if more data is available
    const [shouldAppend, setShouldAppend] = useState(false); // Track if more data is available

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(queryParams.entries());
        setSearchParams(params);
        setShouldAppend(false);
    }, [location.search]);

    const fetchRooms = async (page = 1, append = false) => {
        try {
            const res = await api.post(`/api/rooms`, { ...searchParams, page });
            const newRooms = res.data.data;
            const { current_page, last_page } = res.data.meta;
            setCurrentPage(current_page);
            setRooms((prevRooms) => (append ? [...prevRooms, ...newRooms] : newRooms));
            setHasMore(current_page < last_page);
        } catch (error) {
            setError('Failed to fetch rooms. Please try again later.');
        } finally {
            setLoading(false);
            setShouldAppend(false);
        }
    };

    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        setLoading(true);
        fetchRooms(1, false);
    }, [searchParams]);

    const loadMoreRooms = () => {
        setLoading(true);
        setShouldAppend(true);
        if (hasMore) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            fetchRooms(nextPage, true); // Fetch and append data
        }
    };

    return (
        <>
            <FilterSection />

            <section>
                <div className="container-fluid pad-bot-40">
                    <div className="col-sm-11 mx-auto">
                        <div className="to-ho-hotel">
                            {loading && shouldAppend === false ? (
                                <div className='room_card_wrapper'>
                                    {Array.from({ length: 12 }).map((_, index) => (
                                        <div className="room_card_single" key={index}>
                                            <div className="ph-item p-0 border-0">
                                                <div className="ph-col-12 p-0">
                                                    <div className="ph-picture"></div>
                                                    <div className="ph-row">
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-2"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : error ? (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            ) : (
                                <RoomGrid rooms={rooms} loading={loading} currentPage={currentPage}/>
                            )}

                            <div className="col-md-12 text-center">
                                {hasMore && rooms && rooms.length > 0 &&  (
                                    <button
                                        className="waves-effect waves-light pl-5 pr-5 btn btn-lg btn-danger mt-5"
                                        style={{ 'fontSize': '16px' }}
                                        onClick={loadMoreRooms}
                                        disabled={loading}
                                    >
                                        {loading ? 
                                            (<><i className="fa fa-refresh fa-spin" aria-hidden="true"></i> Loading...</>) 
                                            : 
                                            (<><i className="fa fa-refresh" aria-hidden="true"></i> Load More</>)
                                        }
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
