// import axios from "axios";
// import SessionHelper from "../session/SessionHelper";

// axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;
// axios.defaults.headers.post['Accept'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.withCredentials = true;
// axios.defaults.withXSRFToken = true;
// axios.interceptors.request.use(function (config) {
//     const token = (SessionHelper.GetAuthSession() !== null) ? SessionHelper.GetAuthSession().token : '';
//     config.headers.Authorization = token ? `Bearer ${token}` : '';
//     return config;
// });

// axios.interceptors.response.use(
//     response => response, 
//     error => {
//         if (error.response) {
//             if (error.response.status === 401 || error.response.status === 403) {
//                 SessionHelper.RemoveAuthSession();
//                 // window.location.href = '/login'; // Example redirect
//                 return Promise.reject('Session expired or unauthorized access. Please log in again.');
//             }
//         }
//         return Promise.reject(error);
//     }
// );


import axios from "axios";
import SessionHelper from "../session/SessionHelper";
import Swal from "sweetalert2";

const API_URL = process.env.REACT_APP_API_DOMAIN;
const CSRF_URL = `${API_URL}/sanctum/csrf-cookie`;

// Create Axios Instance
const api = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Required for cookies
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
});

// Function to fetch and store CSRF token
const getCsrfToken = async () => {
    try {
        await axios.get(CSRF_URL, { withCredentials: true });
        sessionStorage.setItem("csrfFetched", "true");
    } catch (error) {
        console.error("Failed to get CSRF token", error);
    }
};

// Axios Request Interceptor - Ensures CSRF & Auth Token is included
api.interceptors.request.use(
    async (config) => {
        if (!sessionStorage.getItem("csrfFetched")) {
            await getCsrfToken();
        }

        // Ensure CSRF token is attached
        const xsrfToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN='))
            ?.split('=')[1];

        if (xsrfToken) {
            config.headers['X-XSRF-TOKEN'] = decodeURIComponent(xsrfToken);
        }
        
        // Add Bearer token if available
        const authSession = SessionHelper.GetAuthSession();
        if (authSession?.token) {
            config.headers.Authorization = `Bearer ${authSession.token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Axios Response Interceptor - Handles CSRF errors & session expiration
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response) {
            const status = error.response.status;

            // CSRF Token Expired - Refresh it and Retry
            if (status === 419) {
                console.warn("CSRF Token expired. Refreshing...");
                await getCsrfToken();
                return api(error.config); // Retry the failed request
            }

            // if (status === 401 || status === 403) {
            //     SessionHelper.RemoveAuthSession();
            //     window.location.href = "/login";
            //     Swal.fire('Error!', error?.response?.data?.message, 'error');
            //     return Promise.reject("Session expired or unauthorized. Please log in again.");
            // }

            // Logout & Redirect
            if (status === 401) {
                SessionHelper.RemoveAuthSession();
                window.location.href = "/login";
                return Promise.reject("Session expired. Redirecting to login.");
            }

            // Unauthorized or Forbidden
            if (status === 403) {
                Swal.fire("Error!", error?.response?.data?.message || "You don't have permission to perform this action.", "error");
                return Promise.reject("Unauthorized access. You do not have permission.");
            }
        }
        return Promise.reject(error);
    }
);

export default api;