import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import WithRouter from '../../../_utility/WithRouter';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SessionHelper from '../../../session/SessionHelper';
import { useModal } from '../../../_context/ModalContext';
import OccupantModal from '../../rooms/OccupantModal';
import OccupantCard from '../../rooms/OccupantCard';
import { useUser } from '../../../_context/UserContext';
import Select from 'react-select'
import useBranches from '../../../data/useBranches';
import BootstrapDatePicker from '../../../component/partials/BootstrapDatePicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import api from '../../../_utility/AxiosConfig';

const BookingEdit = (props) => {
    const booking_id = props.params.booking_id;
    const { authUserId } = useUser();
    const { openModal } = useModal();
    const [apartment, setApartment] = useState([]);
    const [roomSeats, setRoomSeats] = useState([]);
    const [roomSeatsLoading, setRoomSeatsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const branches = useBranches();
    const [bookingInfo, setBookingInfo] = useState(() => {
        return {
            branch_id: null,
            apartment_id: null,
            occupancy_type: '',
            total_adults: 0,
            total_children: 0,
            number_of_seats: 0,
            dateRange: '',
            date: '',
            booking_id: booking_id,
            auth_user_id: authUserId,
            room_type: [],
            booking_amount: 0,
            admission_fee: 0,
            total_amount: 0,
            admission_discount_rate: 0,
            admission_discount_type: 'Fixed',
            net_admission_fee: 0,
            default_conditional_fee: 0,
            conditional_fee: 0,
            net_total_amount: 0,
            due_amount: 0,
            payments: [{ id: '', payment_method: 'Bkash', account_id: '', paid_amount: 0, due_amount: 0, transaction_id: '', paid_at: '' }],
            occupants: [],
            currentSeatId: null,
            currentRoomId: null,
            currentOccupant: null,
            showOccupantModal: false,
            status: 'Temporary',
            error: '',
            errors: [],
            processing: false,
        };
    });

    const branchesOptions = branches.map(branch => ({
        value: branch.id,
        label: branch.name,
        address: branch.address,
        apartments: branch.apartments,
    }));

    const [apartments, setApartments] = useState(() => {
        const selectedBranch = branchesOptions.find(option => option.value === bookingInfo.branch_id);
        return selectedBranch ? selectedBranch.apartments : [];
    });

    const apartmentsOptions = apartments.map(apartment => ({
        value: apartment.id,
        label: apartment.name,
        address: apartment.address
    }));

    useEffect(() => {
        if (!booking_id) return;
        const fetchBookingDetails = async () => {
            try {
                const { data: bookingData } = await api.get(`/api/edit/booking/${booking_id}/details`);
                
                const selectedBranch = branchesOptions.find(option => option.value === bookingData?.apartment?.branch_id);
                const updatedApartments = selectedBranch ? selectedBranch.apartments : [];
                setApartments(updatedApartments);

                setBookingInfo(prevState => ({
                    ...prevState,
                    branch_id: bookingData?.apartment?.branch_id || null,
                    apartment_id: bookingData?.apartment?.id || null,
                    occupancy_type: bookingData.occupancy_type || '',
                    total_adults: bookingData.total_adults || 0,
                    total_children: bookingData.total_children || 0,
                    number_of_seats: bookingData.number_of_seats || 0,
                    date: bookingData.check_in_date || '',
                    dateRange: bookingData.check_out_date ? [bookingData.check_in_date, bookingData.check_out_date] : [],
                    room_type: Array.isArray(bookingData.room_type) ? bookingData.room_type : [bookingData.room_type].filter(Boolean),
                    booking_amount: bookingData.booking_amount || 0,
                    admission_fee: bookingData.admission_fee || 0,
                    total_amount: bookingData.total_amount || 0,
                    admission_discount_rate: bookingData.admission_discount_rate || 0,
                    admission_discount_type: bookingData.admission_discount_type || 'Fixed',
                    net_admission_fee: bookingData.net_admission_fee || 0,
                    default_conditional_fee: bookingData.default_conditional_fee || 0,
                    conditional_fee: bookingData.conditional_fee || 0,
                    net_total_amount: bookingData.net_total_amount || 0,
                    due_amount: bookingData.due_amount || 0,
                    payments: bookingData.payments?.length
                        ? bookingData.payments.map(payment => ({
                              id: payment.id || '',
                              payment_method: payment.payment_method || 'Bkash',
                              account_id: payment.account_id || '',
                              paid_amount: payment.paid_amount || 0,
                              due_amount: payment.due_amount || 0,
                              transaction_id: payment.transaction_id || '',
                              paid_at: payment.paid_at || ''
                          }))
                        : prevState.payments, 
                    occupants: bookingData.occupants || [],
                }));
    
            } catch (error) {
                toast.error('Unable to load booking details.');
                console.error('Unable to load booking details.', error);
            }
        };
    
        fetchBookingDetails();
    }, [booking_id]);

    const findSeatById = (seatId) => {
        return roomSeats
            .flatMap((room) => room.seats) // Flatten all seats
            .find((seat) => seat.id === seatId); // Find the seat
    };

    const updateState = (field, value) => {
        setBookingInfo((prevState) => {
            const updatedState = {
                ...prevState,
                [field]: value,
            };

            return updatedState;
        });
    };

    const increment = (key) => {
        if (key === 'total_adults') {
            setBookingInfo((prev) => ({
                ...prev,
                total_adults: prev['total_adults'] + 1,
                number_of_seats: prev['total_adults'] + 1,
            }));
        } else {
            setBookingInfo((prev) => ({
                ...prev,
                [key]: prev[key] + 1,
            }));
        }
    };

    const decrement = (key) => {
        if (key === 'number_of_seats') {
            setBookingInfo((prev) => ({
                ...prev,
                [key]: prev[key] > prev['total_adults'] ? prev[key] - 1 : prev['total_adults'],
            }));
        } else {
            setBookingInfo((prev) => ({
                ...prev,
                [key]: prev[key] > 0 ? prev[key] - 1 : 0,
            }));
        }
    };

    useEffect(() => {
        let timeoutId;

        const fetchApartmentBranchDetails = async () => {
            try {
                const response = await api.get(`/api/apartments/branch/${bookingInfo.apartment_id}/details`);
                const apartmentData = response.data;

                setApartment(apartmentData ?? []);

                setBookingInfo((prevData) => ({
                    ...prevData,
                    default_conditional_fee: apartmentData?.branch?.security_fee || 0,
                    conditional_fee: apartmentData?.branch?.security_fee || 0,
                    admission_fee: apartmentData?.branch?.admission_fee || 0,
                    admission_discount_rate: apartmentData?.branch?.discount_rate || 0,
                    admission_discount_type: apartmentData?.branch?.discount_rate_type || '',
                    net_admission_fee: apartmentData?.branch?.net_admission_fee || 0,
                    payments: [
                        {
                            ...bookingInfo.payments[0],
                            account_id: apartmentData?.branch?.accounts?.find(account => account.default === 1)?.id,
                        },
                    ],
                }));
            } catch (error) {
                setApartment([]);
                toast.error('Unable to load branch details.');
                console.error('Error fetching apartment details:', error);
            }
        };

        if (bookingInfo.apartment_id) {
            timeoutId = setTimeout(fetchApartmentBranchDetails, 300);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [bookingInfo.apartment_id]);

    useEffect(() => {
        setBookingInfo(prevState => ({
            ...prevState,
            auth_user_id: authUserId
        }));
    }, [authUserId]);

    const [debounceTimeout, setDebounceTimeout] = useState(null);

    useEffect(() => {
        setRoomSeatsLoading(true);
        const {
            booking_id,
            room_type,
            branch_id,
            apartment_id,
            date,
            dateRange,
            number_of_seats,
            occupancy_type,
        } = bookingInfo;
        if (
            !!apartment_id &&
            (!!date || (dateRange && dateRange.length)) &&
            !!occupancy_type &&
            !!number_of_seats
        ) {
            const queryData = {
                booking_id,
                room_type,
                branch_id,
                apartment_id,
                date,
                dateRange,
                number_of_seats,
                occupancy_type,
            };
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            const timeout = setTimeout(() => {
                    api.post(`/api/booking/seats/query`, queryData)
                        .then(res => {
                            setRoomSeats(res.data.data);
                            setRoomSeatsLoading(false);
                        })
                        .catch(error => {
                            console.error("Error fetching rooms:", error);
                        });
            }, 300);

            setDebounceTimeout(timeout);
        }
    }, [
        bookingInfo.branch_id,
        bookingInfo.apartment_id,
        bookingInfo.date,
        bookingInfo.dateRange,
        bookingInfo.number_of_seats,
        bookingInfo.room_type,
        bookingInfo.occupancy_type,
    ]);

    useEffect(() => {
        const {
            occupancy_type,
            booking_amount = 0,
            net_admission_fee = 0,
        } = bookingInfo;

        const conditionalFee = parseFloat(getFee(bookingInfo)) || 0;
        const netAdmissionFee = parseFloat(net_admission_fee) || 0;
        const parsedBookingAmount = parseFloat(booking_amount) || 0;

        let totalAmount = 0;

        if (occupancy_type === 'Permanent') {
            totalAmount += netAdmissionFee;
        }

        totalAmount += conditionalFee + parsedBookingAmount;

        setBookingInfo((prevData) => ({
            ...prevData,
            net_total_amount: totalAmount,
        }));
    }, [
        bookingInfo.occupancy_type,
        bookingInfo.booking_amount,
        bookingInfo.net_admission_fee,
        bookingInfo.conditional_fee,
    ]);

    const calculateSeatAmount = (seat) =>
        bookingInfo.occupancy_type === 'Permanent' ? parseFloat(seat.price_per_month) : parseFloat(seat.price_per_night);

    const handleCheckboxChange = (seatId, roomId, checked) => {
        const seat = findSeatById(seatId);
        const seatAmount = calculateSeatAmount(seat);
        if (!checked) {
            Swal.fire({
                title: "Are you sure?",
                text: "Do you really want to remove this seat? This action cannot be undone.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                        api.delete(`/api/bookings/seat/${bookingInfo.booking_id}/${seatId}/remove`)
                            .then(response => {
                                if (response.data.success) {
                                    setBookingInfo(prev => {
                                        const updatedOccupants = prev.occupants.filter(
                                            (occupant) => occupant.seat_id !== seatId
                                        );
                                        return {
                                            ...prev,
                                            occupants: updatedOccupants,
                                            net_total_amount: prev.net_total_amount - seatAmount,
                                            conditional_fee: prev.occupancy_type === 'Permanent'
                                                ? (prev.conditional_fee >= seatAmount ? prev.conditional_fee - seatAmount : 0)
                                                : prev.conditional_fee,
                                            booking_amount: prev.booking_amount - seatAmount,
                                            errors: [],
                                            error: '',
                                            processing: false,
                                        };
                                    });
                                    Swal.fire({
                                        title: "Deleted!",
                                        text: response.data.message,
                                        icon: "success"
                                    });
                                }
                            })
                            .catch(() =>
                                toast.error('Failed to delete the seat.')
                            );
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire({
                        title: "Cancelled",
                        text: "Your seat data is safe :)",
                        icon: "error"
                    });
                }
            });
            return;
        }
        setBookingInfo(prev => ({
            ...prev,
            currentSeatId: seatId,
            currentRoomId: roomId,
            showOccupantModal: true,
            errors: [],
            error: '',
            processing: false,
        }));
    };

    const handleAddEditOccupant = (seatId, roomId, occupant) => {
        setBookingInfo((prev) => ({
            ...prev,
            currentSeatId: seatId,
            currentRoomId: roomId,
            currentOccupant: occupant,
            showOccupantModal: true,
            errors: [],
            error: '',
            processing: false,
        }));
    };

    const saveBooking = (occupantData = [], finalSave) => {
        setBookingInfo((prev) => {
            return {
                ...prev,
                errors: [],
                error: '',
                processing: true
            };
        });

        const newOccupants = occupantData && Object.keys(occupantData).length > 0
            ? [
                ...bookingInfo.occupants.filter((occupant) => occupant.seat_id !== occupantData.seatId),
                {
                    id: occupantData?.id || '',
                    has_booked: occupantData?.has_booked || false,
                    relation: occupantData?.relation || '',
                    name: occupantData?.name || '',
                    email: occupantData?.email || '',
                    contact_number: occupantData?.contact_number || '',
                    gender: occupantData?.gender || '',
                    room_id: occupantData?.roomId || '',
                    seat_id: occupantData?.seatId || '',
                    dob: occupantData?.dob || '',
                    address: occupantData?.address || '',
                    nationality: occupantData?.nationality || '',
                    identification_type: occupantData?.identification_type || '',
                    identification_number: occupantData?.identification_number || '',
                    has_children: occupantData?.has_children || false,
                    childrens: occupantData?.childrens || [],
                }
            ]
            : bookingInfo.occupants;

        const bookingData = {
            booking_id: bookingInfo.booking_id,
            apartment_id: bookingInfo.apartment_id,
            occupancy_type: bookingInfo.occupancy_type,
            total_adults: bookingInfo.total_adults,
            total_children: bookingInfo.total_children,
            number_of_seats: bookingInfo.number_of_seats,
            dateRange: bookingInfo.dateRange,
            date: bookingInfo.date,
            user_id: bookingInfo.auth_user_id,
            payments: bookingInfo.payments,
            occupants: newOccupants,
            finalSave: finalSave,
            status: finalSave ? 'Pending' : 'Temporary',
        };
            api.post(`/api/store/booking`, bookingData)
                .then((res) => {
                    setBookingInfo({
                        ...bookingInfo,
                        errors: [],
                        error: '',
                        processing: false,
                        booking_amount: res.data.data.booking.booking_amount,
                        net_total_amount: res.data.data.booking.net_total_amount,
                        default_conditional_fee: res.data.data.booking.default_conditional_fee,
                        conditional_fee: res.data.data.booking.conditional_fee,
                        booking_id: res.data.data.booking.id,
                        user_id: res.data.data.booking.user_id,
                        status: res.data.data.booking.status,
                        occupants: res.data.data.occupants,
                        // ForOccupantModal
                        currentSeatId: null,
                        currentRoomId: null,
                        currentOccupant: null,
                        showOccupantModal: false,
                    });

                    if (res.data.data.booking.status === 'Pending') {
                        navigate(`/confirm/${res.data.data.booking.id}/booking`, {
                            state: {
                                booking_id: res.data.data.booking.id,
                            },
                        });
                    }
                })
                .catch((res) => {
                    setBookingInfo({
                        ...bookingInfo,
                        errors: res.response.data.errors || [],
                        error: ['Error occurred while processing your booking. Please try again.'],
                        // error: res.response.data.message ? res.response.data.message : ['Error occurred while processing your booking. Please try again.'],
                        processing: false,
                    });
                });
    };

    const handleDateApply = (date) => {
        setBookingInfo((bookingInfo) => ({
            ...bookingInfo,
            dateRange: '',
            date: date
        }));
    };

    const handleDateRangeSelect = ({ start, end }) => {
        setBookingInfo((bookingInfo) => ({
            ...bookingInfo,
            date: '',
            dateRange: [start, end]
        }));
    };

    const getFee = (info) => {
        if (info.occupancy_type === 'Permanent') {
            return (info.booking_amount || 0) * (info.total_adults ?? 1);
        }
        return (info.default_conditional_fee || 0) * (info.total_adults ?? 0);
    };

    const feeLabel = bookingInfo.occupancy_type !== 'Permanent' ? 'Security Fee' : 'Advance Fee';
    return (
        <>
            <section>
                <div className="inn-body-section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-sm-12 confirm_booking_wrapper">
                                <Link to={`/dashboard`} className='confirm_booking_title'><i className="fa fa-chevron-left"></i> Edit booking</Link>

                                <div className='form-group mb-3 confirm_booking_info border-bottom'>
                                    <div className='d-flex justify-content-between w-100'>
                                        <div className='booking_info_item'>
                                            <strong>Occupancy</strong>
                                            {bookingInfo.occupancy_type}
                                        </div>
                                        <div className='booking_info_edit_btn'>
                                            <button
                                                className='btn btn-light'
                                                onClick={() => {
                                                    setBookingInfo({
                                                        ...bookingInfo,
                                                        date: '',
                                                        dateRange: '',
                                                        occupancy_type: bookingInfo.occupancy_type === 'Permanent' ? 'Guest' : 'Permanent',
                                                    });
                                                }}
                                            >
                                                <i className='fa fa-refresh'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 col-lg-6'>
                                            <div className='booking_info_item w-100'>
                                                <strong>Branch</strong>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        options={branchesOptions}
                                                        onChange={(option) => {
                                                            setBookingInfo({
                                                                ...bookingInfo,
                                                                branch_id: option.value,
                                                                apartment_id: option.apartments ? option.apartments[0].id : null,
                                                            });
                                                            setApartments(option.apartments || []);
                                                        }}
                                                        value={branchesOptions.find(option => option.value === bookingInfo.branch_id) || null}
                                                        formatOptionLabel={({ label, address }) => (
                                                            <div>
                                                                <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p>
                                                                <p style={{ margin: 0 }}>{address}</p>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-6'>
                                            <div className='booking_info_item w-100'>
                                                <strong>Apartment</strong>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        options={apartmentsOptions}
                                                        onChange={(option) => updateState('apartment_id', option.value)}
                                                        value={apartmentsOptions.find(option => option.value === bookingInfo.apartment_id) || null}
                                                        formatOptionLabel={({ label, address }) => (
                                                            <div>
                                                                <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p>
                                                                <p style={{ margin: 0 }}>{address}</p>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12 col-lg-6'>
                                            <div className='booking_info_item w-100'>
                                                <strong>Room Type</strong>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        value={
                                                            bookingInfo.room_type ? bookingInfo.room_type.map((type) => ({ value: type, label: type })) : []
                                                        }
                                                        isMulti
                                                        name="room_type"
                                                        options={[
                                                            { value: 'Single', label: 'Single' },
                                                            { value: 'Double', label: 'Double' },
                                                            { value: 'Dormitory', label: 'Dormitory' },
                                                        ]}
                                                        onChange={(selectedOptions) => {
                                                            const selectedRoomTypes = selectedOptions.map(option => option.value);
                                                            updateState('room_type', selectedRoomTypes);
                                                        }}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-lg-6'>
                                            <div className='w-100'>
                                                <div className='booking_info_item'>
                                                    {bookingInfo.occupancy_type === 'Permanent' ? (
                                                        <>
                                                            <strong>Date</strong>
                                                            <BootstrapDatePicker
                                                                key="permanent"
                                                                onDateSelect={handleDateApply}
                                                                isRange={false}
                                                                initialDate={bookingInfo.date}
                                                                placeholderText={'Check In'}
                                                                initialSettings={{
                                                                    minDate: moment().startOf('day'),
                                                                }}
                                                                className={'form-control'}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>Daterange</strong>
                                                            <BootstrapDatePicker
                                                                key="guest"
                                                                onDateSelect={handleDateRangeSelect}
                                                                isRange={true}
                                                                initialDate={bookingInfo.dateRange}
                                                                placeholderText={'Check In ~ Check Out'}
                                                                initialSettings={{
                                                                    minDate: moment().startOf('day'),
                                                                }}
                                                                className={'form-control'}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {bookingInfo.occupancy_type === 'Permanent' ? (
                                        <>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='booking_info_item'>
                                                    <strong>Seats</strong>
                                                    <span>
                                                        {bookingInfo.number_of_seats >= 0 && (
                                                            <span>
                                                                {bookingInfo.number_of_seats} Seat{bookingInfo.number_of_seats > 1 ? 's' : ''}
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className='booking_info_edit_btn'>
                                                    <div className='input_counter pull-right'>
                                                        <button onClick={() => decrement('number_of_seats')}>-</button>
                                                        <strong className='counter'>{bookingInfo.number_of_seats}</strong>
                                                        <button onClick={() => increment('number_of_seats')}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='booking_info_item'>
                                                    <strong>Adults</strong>
                                                    <span>
                                                        {bookingInfo.total_adults >= 0 && (
                                                            <span>
                                                                {bookingInfo.total_adults} Adult{bookingInfo.total_adults > 1 ? 's' : ''}
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className='booking_info_edit_btn'>
                                                    <div className='input_counter pull-right'>
                                                        <button onClick={() => decrement('total_adults')}>-</button>
                                                        <strong className='counter'>{bookingInfo.total_adults}</strong>
                                                        <button onClick={() => increment('total_adults')}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='booking_info_item'>
                                                    <strong>Childrens</strong>
                                                    <span>
                                                        {bookingInfo.total_children >= 0 && (
                                                            <span>
                                                                {bookingInfo.total_children} Child{bookingInfo.total_children > 1 ? 'ren' : ''}
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className='booking_info_edit_btn'>
                                                    <div className='input_counter pull-right'>
                                                        <button onClick={() => decrement('total_children')}>-</button>
                                                        <strong className='counter'>{bookingInfo.total_children}</strong>
                                                        <button onClick={() => increment('total_children')}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='booking_info_item'>
                                                    <strong>Seats</strong>
                                                    <span>
                                                        {bookingInfo.number_of_seats >= 0 && (
                                                            <span>
                                                                {bookingInfo.number_of_seats} Seat{bookingInfo.number_of_seats > 1 ? 's' : ''}
                                                            </span>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className='booking_info_edit_btn'>
                                                    <div className='input_counter pull-right'>
                                                        <button onClick={() => decrement('number_of_seats')}>-</button>
                                                        <strong className='counter'>{bookingInfo.number_of_seats}</strong>
                                                        <button onClick={() => increment('number_of_seats')}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className='d-flex justify-content-between w-100'>
                                        <div className='booking_info_item'>
                                            <strong>{feeLabel} (BDT)</strong>
                                            <strong>৳{getFee(bookingInfo)}</strong>
                                        </div>
                                    </div>

                                    {
                                        bookingInfo.occupancy_type === 'Permanent' && (
                                            <>
                                                <div className='d-flex justify-content-between w-100'>
                                                    <div className='booking_info_item'>
                                                        <strong>Admission Fee (BDT)</strong>
                                                        <strong>৳{bookingInfo.admission_fee ?? 0} <span className='text-danger'>({`-${bookingInfo.admission_discount_rate ?? 0}${bookingInfo.admission_discount_type === 'Fixed' ? 'Tk' : '%'}`})</span></strong>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    <div className='d-flex justify-content-between w-100'>
                                        <div className='booking_info_item'>
                                            <strong>Net Total (BDT)</strong>
                                            <strong>৳{bookingInfo.net_total_amount ?? 0}</strong>
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group confirm_booking_info'>
                                    <h3>Select Seats</h3>
                                    {roomSeatsLoading === false ? (
                                        <>
                                            <div className='overflow-x-auto'>
                                                {
                                                    roomSeats.map((room, index) => (
                                                        <>
                                                            <div className='form-group'>
                                                                <div style={{ "display": "grid", "grid-template-columns": "130px 260px 200px 1fr", "gap": "1rem" }}>
                                                                    <div className='d-flex flex-column justify-content-center'>
                                                                        {room.featured_image && room.featured_image.data_url ? (
                                                                            <img
                                                                                src={room.featured_image.data_url}
                                                                                alt={room.title}
                                                                                className='rounded mt-2 mb-2'
                                                                                style={{ 'maxWidth': '150px', 'maxHeight': '110px', 'objectFit': 'cover' }}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="/assets/images/room/4.jpg"
                                                                                alt="Room"
                                                                                className='rounded mt-2 mb-2'
                                                                                style={{ 'maxWidth': '150px', 'maxHeight': '110px', 'objectFit': 'cover' }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                    <div className='mr-5 d-flex flex-column justify-content-center'>
                                                                        <p><strong style={{ 'fontSize': '14px' }}>{room.branch?.name}</strong></p>
                                                                        <p><strong style={{ 'fontSize': '12px', 'color': '#4c4a4a' }}>{room.apartment?.title}</strong></p>
                                                                        <p style={{ 'fontSize': '13px' }}>{room.apartment?.address}</p>
                                                                    </div>
                                                                    <div className='mr-5 d-flex flex-column justify-content-center'>
                                                                        <p><strong>{room.title}</strong></p>
                                                                        <p>
                                                                            <label className='badge badge-info p-1 mr-1'>{room?.floor?.name}</label>
                                                                            <label className='badge badge-primary p-1'>{room?.floor?.unit?.name}</label>
                                                                        </p>
                                                                        <p>
                                                                            <span className='d-inline-block mr-2'><strong className='text-primary'>{room.total_seats} Seats</strong></span>
                                                                            <span className='d-inline-block'><strong className='text-success'>{room.available_seats} Available</strong></span>
                                                                        </p>
                                                                    </div>
                                                                    <div className='d-flex flex-column justify-content-center'>
                                                                        <div className="grid-container" style={{ "display": "grid", "grid-template-columns": "repeat(auto-fit, minmax(220px, 1fr))", "gap": "1rem" }}>
                                                                            {
                                                                                room.seats.map((seat, index) => (
                                                                                    <div key={index} className='grid-item'>
                                                                                        <div className="form-check form-check-inline">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id={`custom-checkbox-${seat.id}`}
                                                                                                className="form-check-input"
                                                                                                disabled={!seat.availablity}
                                                                                                checked={bookingInfo.occupants.some((occupant) => occupant.seat_id === seat.id)}
                                                                                                onChange={(e) => handleCheckboxChange(seat.id, room.id, e.target.checked)}
                                                                                            />
                                                                                            <label title="" for={`custom-checkbox-${seat.id}`} className="form-check-label">
                                                                                                <p className="text-primary">{seat.seat_type} Seat</p>
                                                                                                <span className="text-black">
                                                                                                    ৳{bookingInfo.occupancy_type === 'Permanent' ? seat.price_per_month : seat.price_per_night} per {bookingInfo.occupancy_type === 'Permanent' ? "month" : "night"}
                                                                                                </span>
                                                                                                {!seat.availablity ? (
                                                                                                    <p>
                                                                                                        <span className="d-inline-block mr-2 mb-0">
                                                                                                            <strong className="text-danger">Already Booked</strong>
                                                                                                        </span>
                                                                                                    </p>
                                                                                                ) : null}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="occupants-group mt-2" id={`occupants_for_room_${room.id}`}>
                                                                    {bookingInfo.occupants
                                                                        .filter((occupant) =>
                                                                            room.seats.some((seat) => String(seat.id) === String(occupant.seat_id)) &&
                                                                            String(occupant.room_id) === String(room.id)
                                                                        )
                                                                        .map((occupant) => (
                                                                            <>
                                                                                <OccupantCard
                                                                                    key={occupant.seat_id}
                                                                                    occupant={occupant}
                                                                                    onClick={() => handleAddEditOccupant(occupant.seat_id, occupant.room_id, occupant)}
                                                                                />
                                                                            </>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="ph-item border-0 p-0">
                                                <div className="ph-col-2 p-0" style={{ 'height': '90px' }}>
                                                    <div className="ph-picture"></div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                                <div className="ph-col-3 mt-3">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                                <div className="ph-col-6 mt-3">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-10"></div>
                                                        <div className="ph-col-2 empty"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ph-item border-0 p-0">
                                                <div className="ph-col-2 p-0" style={{ 'height': '90px' }}>
                                                    <div className="ph-picture"></div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                                <div className="ph-col-3 mt-3">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                                <div className="ph-col-6 mt-3">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-10"></div>
                                                        <div className="ph-col-2 empty"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ph-item border-0 p-0">
                                                <div className="ph-col-2 p-0" style={{ 'height': '90px' }}>
                                                    <div className="ph-picture"></div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                                <div className="ph-col-3 mt-3">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                                <div className="ph-col-6 mt-3">
                                                    <div className="ph-row">
                                                        <div className="ph-col-12"></div>
                                                        <div className="ph-col-10"></div>
                                                        <div className="ph-col-2 empty"></div>
                                                        <div className="ph-col-8"></div>
                                                        <div className="ph-col-4 empty"></div>
                                                        <div className="ph-col-6"></div>
                                                        <div className="ph-col-6 empty"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <OccupantModal
                                        showOccupantModal={bookingInfo.showOccupantModal}
                                        onClose={() => setBookingInfo(prev => ({ ...prev, showOccupantModal: false }))}
                                        seatId={bookingInfo.currentSeatId}
                                        roomId={bookingInfo.currentRoomId}
                                        currentOccupant={bookingInfo.currentOccupant}
                                        onSave={saveBooking}
                                        errors={bookingInfo.errors}
                                        processing={bookingInfo.processing}
                                    />
                                </div>

                                {bookingInfo?.errors && (
                                    <>
                                        {Object.keys(bookingInfo.errors).map((field) => (
                                            <>
                                                {Array.isArray(bookingInfo.errors[field]) && (
                                                    bookingInfo.errors[field].map((message, index) => (
                                                        <div key={index} className="alert alert-danger" role="alert">{message}</div>
                                                    ))
                                                )}
                                            </>
                                        ))}
                                    </>
                                )}

                                {/* showing all errors */}
                                {/* {bookingInfo?.errors && (
									<>
										{Object.keys(bookingInfo.errors).map((field) => (
											<div key={field} className="alert alert-danger" role="alert">
												{Array.isArray(bookingInfo.errors[field]) ? (
													bookingInfo.errors[field].map((message, index) => (
														<>{message}</>
													))
												) : typeof bookingInfo.errors[field] === "object" ? (
													Object.keys(bookingInfo.errors[field]).map((subField) => (
														<>
															<div key={subField}>
															{
																Array.isArray(bookingInfo.errors[field][subField]) ? (
																	bookingInfo.errors[field][subField].map((childError, index) => (
																		<>
																			{typeof childError === 'object' && !Array.isArray(childError) ? (
																			Object.keys(childError).map((childKey) => (
																				<>
																				{Array.isArray(childError[childKey]) ? (
																					childError[childKey].map((message, messageIndex) => (
																						<div key={messageIndex}>{message}</div>
																					))
																				) : (
																					<>{String(childError[childKey])}</>
																				)}
																				</>
																			))
																			) : (
																				<>{String(childError)}</>
																			)}
																		</>
																	))
																) : (
																	typeof bookingInfo.errors[field][subField] === 'object' && !Array.isArray(bookingInfo.errors[field][subField]) ? (
																	<>
																		{Object.keys(bookingInfo.errors[field][subField]).map((key) => (
																			<>
																				{Array.isArray(bookingInfo.errors[field][subField][key]) ? (
																				bookingInfo.errors[field][subField][key].map((subFieldError, index) => (
																					<div>{String(subFieldError)}</div>
																				))
																				) : typeof bookingInfo.errors[field][subField][key] === 'object' ? (
																				Object.keys(bookingInfo.errors[field][subField][key]).map((innerKey) => (
																					<div key={innerKey}>
																					{Array.isArray(bookingInfo.errors[field][subField][key][innerKey]) ? (
																						bookingInfo.errors[field][subField][key][innerKey].map((message, messageIndex) => (
																						<>{String(message)}</>
																						))
																					) : (
																						<>{String(bookingInfo.errors[field][subField][key][innerKey])}</>
																					)}
																					</div>
																				))
																				) : (
																					<>{String(bookingInfo.errors[field][subField][key])}</>
																				)}
																			</>
																		))}
																	</>
																	) : (
																		<>{String(bookingInfo.errors[field][subField])}</>
																	)
																)
															}
															</div>
														</>
													))
												) : (
													<div>{bookingInfo.errors[field]}</div>
												)}
											</div>
										))}
									</>
								)} */}

                                <div className='form-group'>
                                    {SessionHelper.GetAuthSession() !== null ? (
                                        <>
                                            <button
                                                className="btn btn-success btn-block"
                                                onClick={() => saveBooking([], true)}
                                                type="button"
                                                disabled={bookingInfo.processing}
                                            >
                                                {bookingInfo.processing ? 'Processing...' : 'Update'}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button className='btn btn-success btn-block' onClick={() => openModal('register')} type='button'>Sign In for Booking</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default WithRouter(BookingEdit);
