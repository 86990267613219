import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import Swal from "sweetalert2";
import SessionHelper from "../../../session/SessionHelper";
import SocialLogin from "./SocialLogin";
import { useUser } from "../../../_context/UserContext";
import api from "../../../_utility/AxiosConfig";
import { ToastContainer, toast } from "react-toastify";
import EmailVerificationModal from "./EmailVerificationModal";

const Register = ({closeModal, setModalMode }) => {
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const { setAuthUserId, setAuthUser } = useUser();
    const [formData, setFormData] = useState({
        emailOrPhone: "",
        password: "",
        confirm_password: "",
        loading: false,
        error: '',
        errors: [],
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: [], error: '', loading: true });
        try {

            const response = await api.post('/api/register', formData);

            if (response.data.status === 400) {
                setFormData({
                    ...formData,
                    errors: response.data.errors,
                    error: response.data.message,
                    loading: false,
                });
                toast.error(response.data.message);
            } else if (response.data.status === 200) {
                SessionHelper.SetAuthSession(response.data.user);
                setAuthUser(response.data.user);
                setAuthUserId(response.data.user.user_id);
                setFormData({
                    ...formData,
                    emailOrPhone: "",
                    password: "",
                    confirm_password: "",
                    loading: false,
                    error: '',
                    errors: [],
                });

                if (response?.data?.with_email) {
                    setModalMode("email-verification")
                }
                else 
                {
                    closeModal();
                    Swal.fire('Success!', response.data.message, 'success');
                }
            }
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.message ||
                'An unknown error occurred.';


            setFormData({
                ...formData,
                error: errorMessage,
                loading: false,
            });

            closeModal();
            Swal.fire('Error!', errorMessage, 'error');
        }
    };


    return (

        <>
            <ToastContainer />
            {showVerificationModal && (
                <EmailVerificationModal
                    closeModal={() => setShowVerificationModal(false)}
                />
            )}
            <Form onSubmit={handleSubmit}>
                {/* <div className="log-in-pop-left">
                    <div style={{ 'position': 'relative', 'zIndex': '10' }}>
                        <h1>Hello there</h1>
                        <p>Welcome to younic home.</p>
                        <h4 style={{ 'fontSize': '18px' }}>Login with social media</h4>
                        <ul className='list-unstyled'>
                            <SocialLogin setModalMode={setModalMode} />
                        </ul>
                    </div>
                </div> */}
                <div className="log-in-pop-right w-100">
                    <a href="#" className="pop-close" onClick={closeModal}><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
                    </a>
                    <h4>Create an Account</h4>
                    <p>If you don’t have an account yet, creating one is quick and easy—just a minute and you’re all set!</p>
                    <div className='form-group'>
                        <div className="input-field">
                            <input type="text"
                                id='emailOrPhone'
                                name='emailOrPhone'
                                autoComplete=""
                                className="form-control form-control-lg"
                                value={formData.emailOrPhone}
                                onChange={handleInputChange}
                                placeholder=''
                            />
                            <label htmlFor='emailOrPhone' className='input-field-label'>Email or Mobile</label>
                        </div>
                        {formData.errors && formData.errors.emailOrPhone && (
                            <p className='mt-0'><strong className='text-danger mt-3'>{formData.errors.emailOrPhone}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <input
                                type={isPasswordVisible ? 'text' : 'password'}
                                id='password'
                                name='password'
                                className="form-control form-control-lg"
                                value={formData.password}
                                onChange={handleInputChange}
                                placeholder=''
                            />
                            <label htmlFor='password' className='input-field-label'>Password</label>
                            <i
                                className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                                style={{ position: 'absolute', top: '50%', right: '10px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                onClick={() => setIsPasswordVisible((prevState) => !prevState)}
                            />
                        </div>
                        {formData.errors && formData.errors.password && (
                            <p className='mt-0'><strong className='text-danger mt-3'>{formData.errors.password}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <input
                                type={isPasswordVisible ? 'text' : 'password'}
                                id='confirm-password'
                                name='confirm_password'
                                className="form-control form-control-lg"
                                value={formData.confirm_password}
                                onChange={handleInputChange}
                                placeholder=''
                            />
                            <label htmlFor='confirm-password' className='input-field-label'>Confirm password</label>
                            <i
                                className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                                style={{ position: 'absolute', top: '50%', right: '10px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                onClick={() => setIsPasswordVisible((prevState) => !prevState)}
                            />
                        </div>
                        {formData.errors && formData.errors.confirm_password && (
                            <p className='mt-0'><strong className='text-danger mt-3'>{formData.errors.confirm_password}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                        {/* {formData.error && <Alert variant="danger">{formData.error}</Alert>} */}
                        <div className="input-field">
                            <Button variant="success" className="btn-block" type="submit" disabled={formData.loading}>
                                {formData.loading ? "Processing..." : "Register"}
                            </Button>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <p>Already a member? <a href="#" onClick={() => setModalMode("login")} className="text-success text-primary">Login here</a></p>
                        <p>or sign up with:</p>
                        <SocialLogin setModalMode={setModalMode} />
                    </div>
                </div>
            </Form>
        </>
    );
}

export default Register;
