import React from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const CountrySelector = ({ value, onChange }) => {
  const options = countryList().getData(); // Get the list of countries

  return (
    <Select
      options={options}
      value={options.find(option => option.label === value) || ''}
      onChange={onChange}
      placeholder="Select a country"
    />
  );
};

export default CountrySelector;