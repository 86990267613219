import React, { useState } from "react";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import api from "../../_utility/AxiosConfig";
import { useParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirm_password: "",
        error: "",
        errors: [],
        processing: false,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const formSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: [], processing: true });

        const data = {
            token,
            email: formData.email,
            password: formData.password,
            confirm_password: formData.confirm_password,
        };

        try {
            const res = await api.post("/api/reset-password", data);

            if (res.data.status === 422) {
                setFormData({
                    ...formData,
                    processing: false,
                    error: res.data.message,
                    errors: res.data.errors,
                });
                toast.error("Validation unsuccessful.");
            } else if (res.data.status === 500) {
                setFormData({
                    ...formData,
                    processing: false,
                    error: res.data.message,
                    errors: res.data.errors,
                });
                Swal.fire("Error!", res.data.message, "error");
            } else if (res.data.status === 200) {
                setFormData({
                    email: "",
                    password: "",
                    confirm_password: "",
                    error: "",
                    errors: [],
                    processing: false,
                });
                Swal.fire("Success!", res.data.message, "success");
                navigate("/");
            }
        } catch (error) {
            setFormData({
                ...formData,
                processing: false,
                errors: error.response?.data?.errors || [],
            });
            toast.error("Validation unsuccessful.");
        }
    };

    return (
        <>
            <ToastContainer />
            <section>
                <div className="inn-body-section">
                    <div className="container">
                        <div className="page-head">
                            <h2>Reset Password</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Please enter your new password below to reset your account credentials securely.</p>
                        </div>

                        <div className="row">
                            <div className="col-sm-8 offset-sm-2">
                                <form onSubmit={formSubmit} className="w-100">
                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                type="email"
                                                className="form-control form-control-lg"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder=""
                                            />
                                            <label htmlFor="email" className="input-field-label">E-mail Address</label>
                                        </div>
                                        {formData.errors?.email && (
                                            <p className="mt-2">
                                                <strong className="text-danger mt-3">
                                                    {formData.errors.email}
                                                </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                id="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                placeholder=""
                                            />
                                            <label htmlFor="password" className="input-field-label">
                                                Password
                                            </label>
                                        </div>
                                        {formData.errors?.password && (
                                            <p className="mt-2">
                                                <strong className="text-danger mt-3">
                                                    {formData.errors.password}
                                                </strong>
                                            </p>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                id="confirm_password"
                                                name="confirm_password"
                                                value={formData.confirm_password}
                                                onChange={handleChange}
                                                placeholder=""
                                            />
                                            <label htmlFor="confirm_password" className="input-field-label">
                                                Confirm Password
                                            </label>
                                        </div>
                                        {formData.errors?.confirm_password && (
                                            <p className="mt-2">
                                                <strong className="text-danger mt-3">
                                                    {formData.errors.confirm_password}
                                                </strong>
                                            </p>
                                        )}
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn-success btn-lg btn-block"
                                        disabled={formData.processing}
                                    >
                                        {formData.processing ? "Processing..." : "Reset Password"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPassword;
