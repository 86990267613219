import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from 'axios';
import SocialLogin from "./SocialLogin";
import Swal from "sweetalert2";
import SessionHelper from "../../../session/SessionHelper";
import { useUser } from "../../../_context/UserContext";
import api from "../../../_utility/AxiosConfig";
import { ToastContainer, toast } from "react-toastify";

const Login = ({ closeModal, setModalMode }) => {
    const { setAuthUserId, setAuthUser } = useUser();

    const [formData, setFormData] = useState({
        emailOrPhone: "",
        password: "",
        loading: false,
        error: '',
        errors: [],
    });

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: [], error: '', loading: true });

        try {
            const response = await api.post('/api/login', formData);
            if (response.data.status === 400 || response.data.status === 401) {
                setFormData({
                    ...formData,
                    errors: response.data.errors,
                    error: response.data.message,
                    loading: false,
                });
                toast.error(response.data.message);
            } else if (response.data.status === 200) {
                SessionHelper.SetAuthSession(response.data.user);
                setAuthUser(response.data.user);
                setAuthUserId(response.data.user.user_id);
                setFormData({
                    ...formData,
                    emailOrPhone: "",
                    password: "",
                    loading: false,
                    error: '',
                    errors: [],
                });

                closeModal();
                Swal.fire('Success!', response.data.message, 'success');

            }
        } catch (error) {
            // Extract a meaningful error message
            const errorMessage =
                error.response?.data?.message || // Server provided message
                error.message ||                // Axios error message
                'An unknown error occurred.';  // Default fallback

            setFormData({
                ...formData,
                error: errorMessage, // Set a string error message
                loading: false,
            });

            closeModal();
            Swal.fire('Error!', errorMessage, 'error');
        }
    };

    return (
        <>
            <ToastContainer />
            <Form onSubmit={handleSubmit}>
                {/* <div className="log-in-pop-left">
                    <div style={{ 'position': 'relative', 'zIndex': '10' }}>
                        <h1>Hello there</h1>
                        <p>Welcome to younic home.</p>
                        <h4 style={{ 'fontSize': '18px' }}>Login with social media</h4>
                        <ul className='list-unstyled'>
                            <SocialLogin setModalMode={setModalMode} />
                        </ul>
                    </div>
                </div> */}
                <div className="log-in-pop-right w-100">
                    <a href="#" className="pop-close" onClick={closeModal}><img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
                    </a>
                    <h4>Login</h4>
                    <p>Already a member? Sign in to access your account.</p>
                    <div className='form-group'>
                        <div className="input-field">
                            <input type="text"
                                id='emailOrPhone'
                                name='emailOrPhone'
                                autoComplete=""
                                className="form-control form-control-lg"
                                value={formData.emailOrPhone}
                                onChange={handleInputChange}
                                placeholder=''
                            />
                            <label htmlFor='emailOrPhone' className='input-field-label'>Email or Mobile</label>
                        </div>
                        {formData.errors && formData.errors.emailOrPhone && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.emailOrPhone}</strong></p>
                        )}
                    </div>
                    <div className='form-group'>
                        <div className="input-field">
                            <input
                                type={isPasswordVisible ? 'text' : 'password'}
                                id='password'
                                name='password'
                                className="form-control form-control-lg"
                                value={formData.password}
                                onChange={handleInputChange}
                                placeholder=''
                            />
                            <label htmlFor='password' className='input-field-label'>Password</label>
                            <i
                                className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                                style={{ position: 'absolute', top: '50%', right: '10px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                onClick={() => setIsPasswordVisible((prevState) => !prevState)}
                            />
                        </div>
                        {formData.errors && formData.errors.password && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.password}</strong></p>
                        )}
                    </div>
                    <div class="row mb-4">
                        <div class="col d-flex justify-content-left">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="1" id="form2Example31" checked="" />
                                <label class="form-check-label" for="form2Example31"> Remember me </label>
                            </div>
                        </div>

                        <div class="col" style={{ 'textAlign':'right' }}>
                            <a href="#" onClick={() => setModalMode("forgot-password")}>Forgot password</a>
                        </div>
                    </div>
                    <div>
                        {/* {formData.error && <Alert variant="danger">{formData.error}</Alert>} */}
                        <div className="input-field">
                            <Button variant="success" className="btn-block" type="submit" disabled={formData.loading}>
                                {formData.loading ? "Processing..." : "Login"}
                            </Button>
                        </div>
                    </div>
                    <div class="text-center mt-3">
                        <p>Not a member? <a href="#" className="text-success" onClick={() => setModalMode("register")}>Create a new account</a></p>
                        <p>or sign up with:</p>
                        <SocialLogin setModalMode={setModalMode} />
                    </div>
                </div>
            </Form>
        </>
    );
}


export default Login;
