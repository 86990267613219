import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const BkashCallbackPage = () => {
  const navigate = useNavigate(); 
  const [searchParams] = useSearchParams();
  const [statusMessage, setStatusMessage] = useState("");
  
  const transactionId = searchParams.get("transaction_id");
  const message = searchParams.get("message");
  const statusParam = searchParams.get("status")?.toLowerCase();

  useEffect(() => {
    if (!statusParam || !message) {
      navigate("/dashboard");
    }
  }, [statusParam, message, navigate]);
  
  // Define status messages
  const statusMessages = {
    completed: { class: "success", msg: "The payment was successful." },
    initiated: { class: "info", msg: "The payment process has started." },
    processing: { class: "info", msg: "The payment is being processed by bKash." },
    pending: { class: "warning", msg: "The transaction is pending, awaiting user action or system confirmation." },
    failed: { class: "danger", msg: "The payment attempt was unsuccessful." },
    canceled: { class: "secondary", msg: "The user canceled the transaction." },
    cancel: { class: "secondary", msg: "The user canceled the transaction." },
    expired: { class: "danger", msg: "The payment request expired without completion." },
    decline: { class: "danger", msg: "The payment was declined by bKash." },
    declined: { class: "danger", msg: "The payment was declined by bKash." },
  };

  useEffect(() => {
    if (statusParam) {
      setStatusMessage(statusMessages[statusParam]?.msg || "Unknown payment status.");
    }
  }, [statusParam]);

  return (
    <section className="inn-body-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="col-lg-12 mt-5 mb-5">
              {statusParam ? (
                <>
                  <h1 className={`mb-5 text-${statusMessages[statusParam]?.class || "danger"}`}>
                    {statusMessage}
                  </h1>
                  {statusParam === "completed" && transactionId && (
                    <>
                      <h3>Transaction ID: <strong className="text-success mb-3">{transactionId}</strong></h3>
                      <p className="text-info">Please store this Transaction ID for future reference.</p>
                    </>
                  )}
                  <p>
                    <Link className="btn btn-primary btn-lg" to="/dashboard">
                      <i className="las la-home font-size-20 align-self-start mr-1"></i> Go To Dashboard
                    </Link>
                  </p>
                </>
              ) : (
                <>
                  <i className="fa fa-refresh fa-spin" style={{ fontSize: "90px", display: "inline-block", marginBottom: "30px", color: "#007bff" }}></i>
                  <h3 className="text-primary">Please wait, we are processing your request...</h3>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BkashCallbackPage;
