import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../_context/UserContext';
import Sidebar from './partials/Sidebar';
import CountrySelector from '../../component/partials/CountrySelector';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import SessionHelper from '../../session/SessionHelper';
import WithRouter from '../../_utility/WithRouter';
import api from '../../_utility/AxiosConfig';

const Profile = () => {
    const { authUser, setAuthUser } = useUser();
    const [formData, setFormData] = useState({
        user_id: authUser?.user_id,
        name: authUser?.detail?.name,
        email: authUser?.email,
        contact_number: authUser?.contact_number,
        gender: authUser?.detail?.gender,
        dob: authUser?.detail?.dob,
        address: authUser?.detail?.address,
        nationality: authUser?.detail?.nationality,
        identification_type: authUser?.detail?.identification_type,
        identification_number: authUser?.detail?.identification_number,
        remarks: authUser?.detail?.remarks,
        profile_image: null,
        message: '',
        error: '',
        errors: [],
        processing: false
    });

    const handleCountryChange = (country) => {
        setFormData({
            ...formData,
            nationality: country.label || "",
        });
    };

    const handleChange = (e) => {
        if (e.target.name === 'profile_image') {
            setFormData({
                ...formData,
                profile_image: e.target.files[0],
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({ ...formData, error: '', message: '', errors: [], processing: true });
        // const data = {
        //     user_id: formData.user_id,
        //     name: formData.name,
        //     email: formData.email,
        //     contact_number: formData.contact_number,
        //     gender: formData.gender,
        //     dob: formData.dob,
        //     address: formData.address,
        //     nationality: formData.nationality,
        //     identification_type: formData.identification_type,
        //     identification_number: formData.identification_number,
        //     remarks: formData.remarks,
        // }
        const data = new FormData();
        data.append('user_id', formData.user_id);
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('contact_number', formData.contact_number);
        data.append('gender', formData.gender);
        data.append('dob', formData.dob);
        data.append('address', formData.address);
        data.append('nationality', formData.nationality);
        data.append('identification_type', formData.identification_type);
        data.append('identification_number', formData.identification_number);
        data.append('remarks', formData.remarks);
        if (formData.profile_image) {
            data.append('profile_image', formData.profile_image);
        }
            api.post('/api/update/profile', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                if (res.data.status === 422) {
                    setFormData({ ...formData, processing: false, errors: res.data.errors });
                }
                else if (res.data.status === 200) {
                    setFormData({
                        ...formData,
                        error: '',
                        message: '',
                        errors: [],
                        processing: false
                    });
                    SessionHelper.SetAuthSession(res.data.data);
                    setAuthUser(res.data.data);
                    Swal.fire('Success!', res.data.message, 'success');
                }
            }).catch((error) => {
                setFormData({ ...formData, error: '', message: error?.message, processing: false, errors: error?.response?.data?.errors });
                toast.error('Validation unsuccessful.');
            });
    }


    return (
        <>
            <section>
                <div className="dashboard mt-0 mb-0">
                    <div className='row'>
                        <div className="col-lg-3 col-sm-12 db-left p-0">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9 col-sm-12 db-cent p-0">
                            <div className="db-cent-1">
                                <p>Hi {authUser?.detail?.name},</p>
                                <h4>Welcome to your profile</h4> </div>
                            <div className="db-profile">
                                <img
                                    src={
                                        formData.profile_image
                                            ? URL.createObjectURL(formData.profile_image)
                                            : authUser?.profile_image
                                                ? authUser.profile_image
                                                : "/assets/images/profile.png"
                                    }
                                    alt="Profile"
                                    style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'cover' }}
                                />
                                <h4>{authUser?.detail?.name}</h4>
                                <p>{authUser?.detail?.address}</p>
                            </div>
                            <div className="db-profile-edit">
                                <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                        <label htmlFor="profile_image">Profile Image:</label>
                                        <input
                                            type="file"
                                            id="profile_image"
                                            name="profile_image"
                                            onChange={handleChange}
                                            className="form-control"
                                            accept="image/*" // Accept only image files
                                        />
                                        {formData.errors && formData.errors.profile_image && (
                                            <p className='mt-2 text-left'>
                                                <strong className='text-danger mt-3'>
                                                    {formData.errors.profile_image}
                                                </strong>
                                            </p>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="nationality">Nationality:</label>
                                        <CountrySelector
                                            defaultOptionLabel="Select a country"
                                            value={formData.nationality || ''}
                                            onChange={handleCountryChange}
                                            valueType="label"
                                        />
                                        {formData.errors && formData.errors.nationality && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.nationality}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name" className="col-form-label">Name:</label>
                                                <div className="input-field">
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        placeholder=''
                                                        required
                                                    />
                                                    <label htmlFor="name" className="input-field-label">Name:</label>
                                                </div>
                                                {formData.errors && formData.errors.name && (
                                                    <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                        {formData.errors.name}
                                                    </strong>
                                                    </p>
                                                )}
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="gender" className="col-form-label">Gender:</label>
                                                <select
                                                    name="gender"
                                                    value={formData.gender}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    style={{ "height": "45px" }}
                                                >
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                {formData.errors && formData.errors.gender && (
                                                    <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                        {formData.errors.gender}
                                                    </strong>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email" className="col-form-label">Email:</label>
                                                <div className="input-field">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        placeholder=''
                                                    />
                                                    <label htmlFor="email" className="input-field-label">E-mail Address</label>
                                                </div>
                                                {formData.errors && formData.errors.email && (
                                                    <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                        {formData.errors.email}
                                                    </strong>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="contact_number" className="col-form-label">Contact Number:</label>
                                                <div className="input-field">
                                                    <input
                                                        type="text"
                                                        name="contact_number"
                                                        id="contact_number"
                                                        value={formData.contact_number}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        placeholder=''
                                                    />
                                                    <label htmlFor="contact_number" className="input-field-label">Contact Number:</label>
                                                </div>
                                                {formData.errors && formData.errors.contact_number && (
                                                    <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                        {formData.errors.contact_number}
                                                    </strong>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="dob" className='input-field-label'>Date of Birth:</label>
                                        <div className="input-field">
                                            <input
                                                type="date"
                                                id="dob"
                                                name="dob"
                                                value={formData.dob}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder=''
                                            />
                                        </div>

                                        {formData.errors && formData.errors.dob && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.dob}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Identification:</label>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <select
                                                    name="identification_type"
                                                    value={formData.identification_type}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    style={{ "height": "45px", "borderRadius": "5px 0 0 5px", }}
                                                >
                                                    <option value="">Select Identification Type</option>
                                                    <option value="Passport">Passport</option>
                                                    <option value="National ID">National ID</option>
                                                    <option value="Birth Certificate">Birth Certificate</option>
                                                </select>
                                            </div>
                                            <div className="input-field" style={{ "flex": "1 1 50%", "height": "45px" }}>
                                                <input
                                                    type="text"
                                                    name="identification_number"
                                                    id='identification_number'
                                                    value={formData.identification_number}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder=''

                                                />
                                                <label htmlFor="identification_number" className='input-field-label'>Identification Number:</label>
                                            </div>
                                        </div>
                                        {formData.errors && formData.errors.identification_type && (
                                            <p className='mt-2 mr-2 text-left float-left d-inline'><strong className='text-danger mt-3'>
                                                {formData.errors.identification_type}
                                            </strong>
                                            </p>
                                        )}
                                        {formData.errors && formData.errors.identification_number && (
                                            <p className='mt-2 text-left float-left d-inline'><strong className='text-danger mt-3'>
                                                {formData.errors.identification_number}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-field">
                                            <input
                                                name="address"
                                                id="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder=''
                                            />
                                            <label htmlFor='address' className='input-field-label'>Address</label>
                                        </div>

                                        {formData.errors && formData.errors.address && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.address}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-field">
                                            <textarea
                                                name="remarks"
                                                id="remarks"
                                                value={formData.remarks}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder=''
                                            />
                                            <label htmlFor='remarks' className='input-field-label'>Remarks</label>
                                        </div>

                                        {formData.errors && formData.errors.remarks && (
                                            <p className='mt-2 text-left'><strong className='text-danger mt-3'>
                                                {formData.errors.remarks}
                                            </strong>
                                            </p>
                                        )}
                                    </div>
                                    <button
                                        className="btn btn-lg btn-block btn-success"
                                        type="submit"
                                        disabled={formData.processing}
                                    >
                                        {formData.processing}
                                        {formData.processing ? 'Processing...' : 'Save'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WithRouter(Profile);
