import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchForm from '../partials/SearchForm';
import { useModal } from '../../_context/ModalContext';
import SessionHelper from '../../session/SessionHelper';
import { useUser } from '../../_context/UserContext';
import moment from 'moment';

const Header = () => {
    const { openModal } = useModal();
    const { authUser, logout } = useUser();

    const location = useLocation();

    const [searchData, setSearchData] = useState({
        isVisible: false,
        occupancy_type: 'Guest',
        paneltype: 'tabs'
    });

    const queryParams = new URLSearchParams(location.search);
    const keyword = queryParams.get('keyword');
    const date = queryParams.get('date');
    const dateRange = queryParams.get('dateRange');
    const dateRangeArray = dateRange ? decodeURIComponent(dateRange).split(',') : [];
    const total_adults = queryParams.get('total_adults');
    const total_children = queryParams.get('total_children');
    const number_of_seats = queryParams.get('number_of_seats');

    const adultsOrSeats = searchData.occupancy_type === 'Guest'
        ? (total_adults > 0 ? total_adults : ' Add') + ' Guest'
        : (number_of_seats > 0 ? number_of_seats : ' Add') + ' Seats';

    const formatDate = (dateStr) => {
        if (dateStr) {
            const getDate = moment(dateStr, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'], true);
            const date = new Date(getDate);
            return date.toLocaleString('en-US', { month: 'short', day: 'numeric' });
        } else {
            return 'Any Day';
        }

    };

    const toggleSearchBar = (occupancy_type) => {
        setSearchData((prevData) => ({
            ...prevData,
            isVisible: true,
            occupancy_type: occupancy_type,
            paneltype: 'btnGroups',
        }));
    };

    const navbarRef = useRef(null);

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setSearchData((prevData) => ({
                ...prevData,
                isVisible: false,
                paneltype: 'tabs',
                occupancy_type: 'Guest',
            }));
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className='fixed-top' ref={navbarRef}>
                <nav className="navbar navbar-light bg-light">
                    <div className="container-fluid pl-0 pr-0">
                        <div className="col-sm-11 mx-auto pl-0 pr-0">
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="navbar-header">
                                    <Link to="/" className="navbar-brand logo w-100 m-0">
                                        <img src="/assets/images/logo1.png" alt="Logo" />
                                    </Link>
                                </div>

                                <div className="search-bar-wrapper d-none d-lg-block d-md-block">
                                    <div className={`search-bar ${searchData.paneltype === 'tabs' ? '' : 'd-none'}`} onClick={() => toggleSearchBar(searchData.occupancy_type || 'Guest')}>
                                        <span className='border-right pr-3 mr-2'>{keyword || 'Anywhere'}</span>
                                        <span className='border-right pr-3 mr-2'>
                                            {
                                                searchData.occupancy_type === 'Guest' ? (
                                                    <>
                                                        {(dateRange && Array.isArray(dateRangeArray)) ? formatDate(dateRangeArray[0]) + ' - ' + formatDate(dateRangeArray[1]) : 'Any Day'}
                                                    </>
                                                ) : (
                                                    <>
                                                        {date ? formatDate(date) : 'Any Day'}
                                                    </>
                                                )
                                            }
                                        </span>
                                        <span>
                                            {adultsOrSeats || 'Add guests'}
                                        </span>
                                        <button className="btn">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                    <ul className={`nav nav-tabs border-0 ${searchData.paneltype === 'btnGroups' ? '' : 'd-none'}`}>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${searchData.occupancy_type === 'Guest' ? 'active' : ''}`} onClick={() => toggleSearchBar('Guest')}>Guest</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${searchData.occupancy_type === 'Permanent' ? 'active' : ''}`} onClick={() => toggleSearchBar('Permanent')}>Permanent</Link>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="nav navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-bars mr-1" aria-hidden="true"></i>
                                            <img 
                                            src={authUser?.profile_image
                                                        ? authUser.profile_image
                                                        : "/assets/images/profile.png"
                                            }
                                             width="33px" 
                                             style={{ 'borderRadius':'50px', 'border':'1px solid #ddd' }}
                                             alt={authUser?.detail?.name} />
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {SessionHelper.GetAuthSession() !== null ? (
                                                <>
                                                    <Link className="dropdown-item" to='/dashboard'>My Account</Link>
                                                    <Link className="dropdown-item" to="" onClick={logout}>Logout</Link>
                                                </>
                                            ) : (
                                                <>
                                                    <Link className="dropdown-item" onClick={() => openModal('register')}>Register</Link>
                                                    <Link className="dropdown-item" onClick={() => openModal('login')}>Log In</Link>
                                                </>
                                            )}
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to='/'>Home</Link>
                                            <Link className="dropdown-item" to='/about-us'>About Us</Link>
                                            <Link to="/contact-us" className="dropdown-item">Contact Us</Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='d-block d-lg-none d-md-none mt-3'>
                                    <div className="search-bar-wrapper">
                                        <div className={`search-bar ${searchData.paneltype === 'tabs' ? '' : 'd-none'}`} onClick={() => toggleSearchBar(searchData.occupancy_type || 'Guest')}>
                                            <span className='border-right pr-3 mr-2'>{keyword || 'Anywhere'}</span>
                                            <span className='border-right pr-3 mr-2'>
                                                {
                                                    searchData.occupancy_type === 'Guest' ? (
                                                        <>
                                                            {(dateRange && Array.isArray(dateRangeArray)) ? formatDate(dateRangeArray[0]) + ' - ' + formatDate(dateRangeArray[1]) : 'Any Day'}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {date ? formatDate(date) : 'Any Day'}
                                                        </>
                                                    )
                                                }
                                            </span>
                                            <span>
                                                {adultsOrSeats || 'Add guests'}
                                            </span>
                                            <button className="btn">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                        <ul className={`nav nav-tabs border-0 ${searchData.paneltype === 'btnGroups' ? '' : 'd-none'}`} style={{ "margin": "0 auto", "width": "fit-content" }}>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${searchData.occupancy_type === 'Guest' ? 'active' : ''}`} onClick={() => toggleSearchBar('Guest')}>Guest</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${searchData.occupancy_type === 'Permanent' ? 'active' : ''}`} onClick={() => toggleSearchBar('Permanent')}>Permanent</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            <SearchForm searchData={searchData} setSearchData={setSearchData} />
                        </div>
                    </div>

                </nav>

            </header>
        </>
    );
}

export default Header;