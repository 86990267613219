import { useState, useEffect } from 'react';
import SessionHelper from '../session/SessionHelper';
import api from '../_utility/AxiosConfig';

function useBranches() {
    const [branches, setBranches] = useState(() => {
        const savedBranches = SessionHelper.GetBranchesSession();
        return savedBranches ?? [];
    });

    useEffect(() => {
        if (!SessionHelper.GetBranchesSession()) {
            api.get('/api/branches').then((res) => {
                const branchData = res.data.data;
                setBranches(branchData);
                SessionHelper.SetBranchesSession(branchData);
            }).catch((error) => {
                console.error("Error fetching branches:", error);
            });
        }
    }, []);

    return branches;
}

export default useBranches;
