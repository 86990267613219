import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import api from "../../_utility/AxiosConfig";
import { useNavigate } from "react-router-dom";
import SessionHelper from "../../session/SessionHelper";
import { useUser } from "../../_context/UserContext";

const Login = () => {
    const navigate = useNavigate();

    const { setAuthUserId, setAuthUser } = useUser();
    const [formData, setFormData] = useState({
        emailOrPhone: "",
        password: "",
        loading: false,
        error: '',
        errors: [],
    });

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: [], error: '', loading: true });

        try {
            const response = await api.post('/api/login', formData);

            if (response.data.status === 400 || response.data.status === 401) {
                setFormData({
                    ...formData,
                    errors: response.data.errors,
                    error: response.data.message,
                    loading: false,
                });
                toast.error(response.data.message);
            } else if (response.data.status === 200) {
                SessionHelper.SetAuthSession(response.data.user);
                setAuthUser(response.data.user);
                setAuthUserId(response.data.user.user_id);
                setFormData({
                    ...formData,
                    emailOrPhone: "",
                    password: "",
                    loading: false,
                    error: '',
                    errors: [],
                });
                navigate('/dashboard');
            }
        } catch (error) {
            // Extract a meaningful error message
            const errorMessage =
                error.response?.data?.message || // Server provided message
                error.message ||                // Axios error message
                'An unknown error occurred.';  // Default fallback

            setFormData({
                ...formData,
                error: errorMessage, // Set a string error message
                loading: false,
            });
            Swal.fire('Error!', errorMessage, 'error');
        }
    };

    return (
        <>
            <ToastContainer />
            <section>
                <div className="inn-body-section">
                    <div className="container">
                        <div className="page-head">
                            <h2>Login</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Already a member? Sign in to access your account. </p>
                        </div>

                        <div className="row">
                            <div className="col-sm-8 offset-sm-2">
                                <form onSubmit={handleSubmit} className="w-100">
                                    <div className='form-group'>
                                        <div className="input-field">
                                            <input type="text"
                                                id='emailOrPhone'
                                                name='emailOrPhone'
                                                autoComplete=""
                                                className="form-control form-control-lg"
                                                value={formData.emailOrPhone}
                                                onChange={handleInputChange}
                                                placeholder=''
                                            />
                                            <label htmlFor='emailOrPhone' className='input-field-label'>Email or Mobile</label>
                                        </div>
                                        {formData.errors && formData.errors.emailOrPhone && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.emailOrPhone}</strong></p>
                                        )}
                                    </div>
                                    <div className='form-group'>
                                        <div className="input-field">
                                            <input
                                                type={isPasswordVisible ? 'text' : 'password'}
                                                id='password'
                                                name='password'
                                                className="form-control form-control-lg"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                placeholder=''
                                            />
                                            <label htmlFor='password' className='input-field-label'>Password</label>
                                            <i
                                                className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                                                style={{ position: 'absolute', top: '50%', right: '10px', cursor: 'pointer', transform: 'translateY(-50%)' }}
                                                onClick={() => setIsPasswordVisible((prevState) => !prevState)}
                                            />
                                        </div>
                                        {formData.errors && formData.errors.password && (
                                            <p className='mt-2'><strong className='text-danger mt-3'>{formData.errors.password}</strong></p>
                                        )}
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn-success btn-lg btn-block"
                                        disabled={formData.loading}
                                    >
                                        {formData.loading ? "Processing..." : "Login"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Login;
