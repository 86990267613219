import React, { useEffect, useRef, useState } from 'react';
import { useUser } from '../../../_context/UserContext';
import Sidebar from '../partials/Sidebar';
import WithRouter from '../../../_utility/WithRouter';
import { navigate, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import api from '../../../_utility/AxiosConfig';

const View = ({props}) => {
    const { authUser } = useUser();
    const [visibleOccupantId, setVisibleOccupantId] = useState(null);
    const [visiblePaymentDetailId, setVisiblePaymentDetailId] = useState(null);
    const [booking, setBooking] = useState([]);
    const { booking_id } = useParams();

    useEffect(() => {
            api.post(`/api/view/${booking_id}/booking`)
                .then(res => {
                    setBooking(res);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
      }, [booking_id]);

    /* @ts-ignore */
    const toggleChildrenVisibility = (id) => {
        setVisibleOccupantId((prevId) => (prevId === id ? null : id));
    };
    /* @ts-ignore */
    const toggleMoneyReceiptsVisibility = (id) => {
        setVisiblePaymentDetailId((prevId) => (prevId === id ? null : id));
    };

    return (

        <section>
            <div className="dashboard mt-0 mb-0">
                <div className='row'>
                    <div className="col-lg-3 col-sm-12 db-left p-0">
                        <Sidebar />
                    </div>
                    <div className="col-lg-9 col-sm-12 db-cent p-0">
                        <div className="db-cent-1">
                            <p className='text-white'>{authUser?.detail?.name},</p>
                            <h4 style={{ color: '#b1b1b1' }}>Welcome to your dashboard</h4> 
                        </div>
                        <div className="db-cent-3 pt-3">
                            <div className="db-cent-table db-com-table">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="section-body">
                                            <div className="section-body-header">
                                                <div className="section-body-header-left">
                                                    <h1 className="section-title">
                                                        Bookings
                                                        <Link to='/dashboard' className="btn btn-icon btn-sm btn-danger ml-2">
                                                            <i className="fa fa-arrow-left"></i><span></span>
                                                        </Link>
                                                    </h1>
                                                    <p className="section-lead">On this page you can view a booking information with details.</p>
                                                </div>
                                            </div>

                                            <div className='col-md-12 p-0'>
                                                {
                                                    booking && (
                                                        <>
                                                            <div className="card card-primary mb-4">
                                                                <div className="card-header pl-2">
                                                                    <h4>Booking Main Information</h4>
                                                                </div>
                                                                <div className="card-body p-0 border-left">
                                                                    <ul className="list-group list-group-horizontal-xl d-flex flex-wrap">
                                                                        {/* <ul className="list-group"> */}
                                                                        <li className="list-group-item p-2 flex-grow-1 border-left-0">
                                                                            <h6 className="mb-1">Branch</h6>
                                                                            <p className='mb-0'>{booking?.data?.apartment?.branch?.name}</p>
                                                                            <p className='mb-0'>{booking?.data?.apartment?.branch?.address}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Apartment</h6>
                                                                            <p className='mb-0'>{booking?.data?.apartment?.name}</p>
                                                                            <p className='mb-0'>{booking?.data?.apartment?.address}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Booked By</h6>
                                                                            <p className='mb-0'>{booking?.data?.booked_by?.name}</p>
                                                                            <p className='mb-0'>{booking?.data?.booked_by?.contact_number}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">User Account</h6>
                                                                            <p className='mb-0'>{booking?.data?.user?.name}</p>
                                                                            <p className='mb-0'>{booking?.data?.user?.contact_number}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Room Type</h6>
                                                                            <p className='mb-0'>{booking?.data?.room_type}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Occupancy</h6>
                                                                            <p className='mb-0'>{booking?.data?.occupancy_type}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Occupant Type</h6>
                                                                            <p className='mb-0'>{booking?.data?.occupant_type}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Total Seats</h6>
                                                                            <p className='mb-0'>{booking?.data?.number_of_seats}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Total Adults</h6>
                                                                            <p className='mb-0'>{booking?.data?.total_adults}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Total Children</h6>
                                                                            <p className='mb-0'>{booking?.data?.total_children}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Total Boarder</h6>
                                                                            <p className='mb-0'>{booking?.data?.total_boarder}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Booking Amount</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.booking_amount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">
                                                                                {`${booking?.data?.occupancy_type !== 'Permanent' ? 'Security Fee' : 'Advance Fee'}`}
                                                                            </h6>
                                                                            <p className='mb-0'>৳{booking?.data?.conditional_fee}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Admission Fee</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.admission_fee}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Total Amount</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.total_amount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Admission Discount</h6>
                                                                            <p className='mb-0'>{booking?.data?.admission_discount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Admission Discount Amount</h6>
                                                                            <p className='mb-0'>{booking?.data?.admission_discount_amount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Net Admission Fee</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.net_admission_fee}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Net Total Amount</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.net_total_amount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Paid Amount</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.paid_amount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Due Amount</h6>
                                                                            <p className='mb-0'>৳{booking?.data?.due_amount}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-0">Arrival</h6>
                                                                            <p>{booking?.data?.check_in_date}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Departure</h6>
                                                                            <p className='mb-0'>{booking?.data?.check_out_date !== '' ? `${booking?.data?.check_out_date}` : ''}</p>
                                                                        </li>

                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Status</h6>
                                                                            <p className='mb-0'>{booking?.data?.status}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Remarks</h6>
                                                                            <p className='mb-0'>{booking?.data?.remarks}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Created At</h6>
                                                                            <p className='mb-0'>{booking?.data?.created_at}</p>
                                                                        </li>
                                                                        <li className="list-group-item p-2 flex-grow-1">
                                                                            <h6 className="mb-1">Updated At</h6>
                                                                            <p className='mb-0'>{booking?.data?.updated_at}</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card card-info mb-4">
                                                                <div className="card-header pl-2">
                                                                    <h4>Occupant Information</h4>
                                                                </div>
                                                                <div className="card-body p-0">
                                                                    <ul className="list-group list-group-horizontal-xl d-flex flex-wrap mb-0 p-0" style={{ 'borderRadius': '0px' }}>
                                                                        {booking?.data?.occupants && booking?.data?.occupants?.length > 0 ? (
                                                                            /* @ts-ignore */
                                                                            booking?.data?.occupants.map((occupant) => (
                                                                                <li className="list-group-item p-0 flex-grow-1 border-0">
                                                                                    <table className="table table-sm table-bordered mb-0">
                                                                                        <tbody>
                                                                                            <tr key={occupant.id}>
                                                                                                <td colSpan={2} className='p-0'>
                                                                                                    <table className='table table-sm mb-0'>
                                                                                                        <tbody>
                                                                                                        <tr className='text-white text-center'>
                                                                                                                <td className='border-bottom-0 border-top-0 border-left-0'>
                                                                                                                    <h6>Room Title</h6>
                                                                                                                    <p style={{ 'margin' : '0px', lineHeight:'normal' }}>{occupant.room.title}</p>
                                                                                                                </td>
                                                                                                                <td className='border-bottom-0 border-top-0'>
                                                                                                                    <h6>Room Number</h6>
                                                                                                                    <p style={{ 'margin' : '0px', lineHeight:'normal' }}>{occupant.room.room_number}</p>
                                                                                                                </td>
                                                                                                                <td className='border-bottom-0 border-top-0'>
                                                                                                                    <h6>Occupancy</h6>
                                                                                                                    <p style={{ 'margin' : '0px', lineHeight:'normal' }}>{occupant.room.seat.occupancy_type}</p>
                                                                                                                </td>
                                                                                                                <td className='border-bottom-0 border-top-0'>
                                                                                                                    <h6>Seat</h6>
                                                                                                                    <p style={{ 'margin' : '0px', lineHeight:'normal' }}>{occupant.room.seat.seat_type}({occupant.room.seat.seat_number})</p>
                                                                                                                </td>
                                                                                                                <td className='border-bottom-0 border-top-0 border-right-0'>
                                                                                                                    <h6>Amount</h6>
                                                                                                                    <p style={{ 'margin' : '0px', lineHeight:'normal' }}>
                                                                                                                        ৳{occupant.occupancy_type === 'Guest' ? occupant.room.seat.price_per_night : occupant.room.seat.price_per_month}
                                                                                                                    </p>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Has Booked</th>
                                                                                                <td>
                                                                                                    {occupant.has_booked ? <label className="badge badge-success">Yes</label> : <label className="badge badge-warning">No</label>}
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Relation</th>
                                                                                                <td>{occupant.relation}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Name</th>
                                                                                                <td>{occupant.name}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>E-mail Address</th>
                                                                                                <td>{occupant.email}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Contact Number</th>
                                                                                                <td>{occupant.contact_number}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Gender</th>
                                                                                                <td>{occupant.gender}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Nationality</th>
                                                                                                <td>{occupant.nationality}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Identification</th>
                                                                                                <td>{occupant.identification_type} : {occupant.identification_number}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Has Children</th>
                                                                                                <td>
                                                                                                    {occupant.has_children ? (
                                                                                                        <>
                                                                                                            <label className="badge badge-success mb-0">Yes</label>
                                                                                                            <button
                                                                                                                onClick={() => toggleChildrenVisibility(occupant.id)}
                                                                                                                className={`btn btn-sm ml-2 float-right ${visibleOccupantId === occupant.id ? 'btn-warning' : 'btn-info'}`}
                                                                                                            >
                                                                                                                {visibleOccupantId === occupant.id ? 'Hide Children' : 'Show Children'}
                                                                                                            </button>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <label className="badge badge-warning mb-0">No</label>
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                            {occupant.has_children && occupant.childrens.length > 0 ? (
                                                                                                <tr key={occupant.id} className={visibleOccupantId === occupant.id ? '' : 'd-none'}>
                                                                                                    <td colSpan={2} className='p-0'>
                                                                                                        <table className='table table-striped table-sm mb-0'>
                                                                                                            <thead>
                                                                                                                <tr className='bg-light text-center'>
                                                                                                                    <td className='border-bottom-0 border-left-0'>Relation</td>
                                                                                                                    <td className='border-bottom-0'>Name</td>
                                                                                                                    <td className='border-bottom-0'>Gender</td>
                                                                                                                    <td className='border-bottom-0 border-right-0'>Identification</td>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {occupant.childrens && occupant.childrens.length > 0 ? (
                                                                                                                    /* @ts-ignore */
                                                                                                                    occupant.childrens.map((child, index) => (
                                                                                                                        <tr className='text-center'>
                                                                                                                            <td
                                                                                                                                className={`border-left-0 ${index === occupant.childrens.length - 1 ? 'border-bottom-0' : ''
                                                                                                                                    }`}
                                                                                                                            >
                                                                                                                                {child.relation}
                                                                                                                            </td>
                                                                                                                            <td className={index === occupant.childrens.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {child.name}
                                                                                                                            </td>
                                                                                                                            <td className={index === occupant.childrens.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {child.gender}
                                                                                                                            </td>

                                                                                                                            <td
                                                                                                                                className={`border-right-0 ${index === occupant.childrens.length - 1 ? 'border-bottom-0' : ''
                                                                                                                                    }`}
                                                                                                                            >
                                                                                                                                {child.identification_type} : {child.identification_number}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    ))
                                                                                                                ) : (
                                                                                                                    <tr className='text-center'>
                                                                                                                        <td colSpan={4} className='border-bottom-0 border-left-0'>No child available</td>
                                                                                                                    </tr>
                                                                                                                )}
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : <></>}

                                                                                            <tr key={occupant.id}>
                                                                                                <th>Created At</th>
                                                                                                <td>{occupant.created_at}</td>
                                                                                            </tr>
                                                                                            <tr key={occupant.id}>
                                                                                                <th>Updated At</th>
                                                                                                <td>{occupant.updated_at}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </li>
                                                                            ))
                                                                        ) : (
                                                                            <li className="list-group-item p-2 flex-grow-1 border-left-0">
                                                                                <strong className='text-danger'>No payment details available</strong>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="card card-success mb-4">
                                                                <div className="card-header pl-2">
                                                                    <h4>Payment Information</h4>
                                                                </div>
                                                                <div className="card-body p-0">
                                                                    <div className='border-left'>
                                                                        <ul className="list-group list-group-horizontal-xl d-flex flex-wrap" style={{ 'borderRadius': '0px' }}>
                                                                            <li className="list-group-item p-2 flex-grow-1 border-left-0">
                                                                                <h6 className="mb-1">Invoice Number</h6>
                                                                                <p className='mb-0'>{booking?.data?.payment?.invoice_number}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Recurring Frequency</h6>
                                                                                <p className='mb-0'>{booking?.data?.payment?.recurrence_frequency}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Recurring Amount</h6>
                                                                                <p className='mb-0'>৳{booking?.data?.payment?.recurring_amount}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Total Amount</h6>
                                                                                <p className='mb-0'>৳{booking?.data?.payment?.total_amount}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Paid Amount</h6>
                                                                                <p className='mb-0'>৳{booking?.data?.payment?.paid_amount}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Due Amount</h6>
                                                                                <p className='mb-0'>৳{booking?.data?.payment?.due_amount}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Status</h6>
                                                                                <p className='mb-0'>{booking?.data?.payment?.status}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Created At</h6>
                                                                                <p className='mb-0'>{booking?.data?.payment?.created_at}</p>
                                                                            </li>
                                                                            <li className="list-group-item p-2 flex-grow-1">
                                                                                <h6 className="mb-1">Updated At</h6>
                                                                                <p className='mb-0'>{booking?.data?.payment?.updated_at}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                    <ul className="list-group list-group-horizontal-xl d-flex flex-wrap mb-0 p-0" style={{ 'borderRadius': '0px' }}>
                                                                        {booking?.data?.payment?.details && booking?.data?.payment?.details?.length > 0 ? (
                                                                            /* @ts-ignore */
                                                                            booking?.data?.payment.details.map((detail) => (
                                                                                <li className="list-group-item p-0 flex-grow-1 border-0">
                                                                                    <table className="table table-sm table-bordered mb-0">
                                                                                        <tbody>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Charge Type</th>
                                                                                                <td>{detail.charge_type}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Charge Description</th>
                                                                                                <td>{detail.charge_description}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Account</th>
                                                                                                <td>{detail.account_id}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Payment Method</th>
                                                                                                <td>{detail.payment_method}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Transaction ID</th>
                                                                                                <td>{detail.transaction_id}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Payment Stage</th>
                                                                                                <td>{detail.payment_stage}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Payment Type</th>
                                                                                                <td>{detail.payment_type}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Paid Amount</th>
                                                                                                <td>
                                                                                                    {detail.moneyReceipts && detail.moneyReceipts.length > 0 ? (
                                                                                                        <>
                                                                                                            {detail.paid_amount}
                                                                                                            <button
                                                                                                                onClick={() => toggleMoneyReceiptsVisibility(detail.id)}
                                                                                                                className={`btn btn-sm ml-2 float-right ${visiblePaymentDetailId === detail.id ? 'btn-warning' : 'btn-info'}`}
                                                                                                            >
                                                                                                                {visiblePaymentDetailId === detail.id ? 'Hide Receipts' : 'Show Receipts'}
                                                                                                            </button>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>{detail.paid_amount}</>
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                            {detail.moneyReceipts && detail.moneyReceipts.length > 0 ? (
                                                                                                <tr key={detail.id} className={visiblePaymentDetailId === detail.id ? '' : 'd-none'}>
                                                                                                    <td colSpan={2} className='p-0'>
                                                                                                        <table className='table table-striped table-sm mb-0'>
                                                                                                            <thead>
                                                                                                                <tr className='bg-light text-center'>
                                                                                                                    <td className='border-bottom-0 border-left-0'>Receipt Number</td>
                                                                                                                    <td className='border-bottom-0'>Account</td>
                                                                                                                    <td className='border-bottom-0'>Receipt Type</td>
                                                                                                                    <td className='border-bottom-0'>Payment Method</td>
                                                                                                                    <td className='border-bottom-0'>Amount</td>
                                                                                                                    <td className='border-bottom-0'>Status</td>
                                                                                                                    <td className='border-bottom-0'>Created At</td>
                                                                                                                    <td className='border-bottom-0'>Created By</td>
                                                                                                                    <td className='border-bottom-0 border-right-0'>Updated By</td>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    /* @ts-ignore */
                                                                                                                    detail.moneyReceipts.map((receipt, index) => (
                                                                                                                        <tr className='text-center'>
                                                                                                                            <td
                                                                                                                                className={`border-left-0 ${index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''
                                                                                                                                    }`}
                                                                                                                            >
                                                                                                                                {receipt.receipt_number}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.account}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.receipt_type}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.payment_method}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.amount}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.status}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.created_at}
                                                                                                                            </td>
                                                                                                                            <td className={index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''}>
                                                                                                                                {receipt.createdBy}
                                                                                                                            </td>
                                                                                                                            <td
                                                                                                                                className={`border-right-0 ${index === detail.moneyReceipts.length - 1 ? 'border-bottom-0' : ''
                                                                                                                                    }`}
                                                                                                                            >
                                                                                                                                {receipt.updatedBy}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : <></>}
                                                                                            <tr key={detail.id}>
                                                                                                <th>Due Amount</th>
                                                                                                <td>{detail.due_amount}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Paid At</th>
                                                                                                <td>{detail.paid_at}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Status</th>
                                                                                                <td>{detail.status}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Notes</th>
                                                                                                <td>{detail.notes}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Created At</th>
                                                                                                <td>{detail.created_at}</td>
                                                                                            </tr>
                                                                                            <tr key={detail.id}>
                                                                                                <th>Updated At</th>
                                                                                                <td>{detail.updated_at}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </li>
                                                                            ))
                                                                        ) : (
                                                                            <li className="list-group-item p-2 flex-grow-1 border-left-0">
                                                                                <strong className='text-danger'>No payment details available</strong>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WithRouter(View);
