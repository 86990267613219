import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import api from "../../../_utility/AxiosConfig";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../../_context/UserContext";

const EmailVerificationModal = ({ closeModal, setModalMode }) => {
    const { authUser } = useUser();

    const [formData, setFormData] = useState({
        user_id: authUser?.user_id,
        code: '',
        loading: false,
        error: '',
        errors: {},
    });

    const [resending, setResending] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData({ ...formData, errors: {}, error: '', loading: true });

        try {
            const response = await api.post("/api/verify/user", {
                user_id: formData.user_id,
                code: formData.code
            });

            if (response.data.status === 200) {
                setFormData({ ...formData, code: '', loading: false, error: '', errors: {} });
                closeModal();
                Swal.fire('Success!', response.data.message, 'success');
            } else {
                setFormData({ ...formData, loading: false, error: response.data.message, errors: response.data.errors || {} });
                toast.error(response.data.message);
            }
        } catch (error) {
            setFormData({ ...formData, loading: false, error: "The verification code is invalid or has expired.", errors: {} });
        }
    };

    const handleResendCode = async () => {
        setResending(true);
        try {
            const response = await api.post("/api/resend-verification", { user_id: formData.user_id });

            if (response.data.status === 200) {
                toast.success("Verification code resent successfully!");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to resend verification code. Try again.");
        }
        setResending(false);
    };

    return (
        <>
            <ToastContainer />
            <Form onSubmit={handleSubmit}>
                <div className="p-5">
                    <a href="#" className="pop-close" onClick={closeModal}>
                        <img style={{ width: '15px' }} src="/assets/images/cancel.png" alt="" />
                    </a>
                    <h5>A verification code has been sent to your email address.</h5>
                    <div className="form-group">
                        <div className="input-field">
                            <input
                                type="text"
                                id="code"
                                name="code"
                                autoComplete="off"
                                className="form-control form-control-lg"
                                value={formData.code}
                                onChange={handleInputChange}
                                placeholder=""
                            />
                            <label htmlFor="code" className="input-field-label">Enter your verification code</label>
                        </div>
                        <a onClick={handleResendCode} href="#" className="text-success" disabled={resending} >{resending ? "Resending..." : "Resend Verification Code"}</a>
                        {formData.errors?.code && (
                            <p className="mt-2"><strong className="text-danger">{formData.errors.code}</strong></p>
                        )}
                        {formData.error && <Alert variant="danger" className="mt-2">{formData.error}</Alert>}
                    </div>
                    <div className="form-group mb-0">
                        <Button variant="primary" type="submit" disabled={formData.loading}>
                            {formData.loading ? "Processing..." : "Submit"}
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default EmailVerificationModal;
