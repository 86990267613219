import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
const options = {
    items: 1,
    margin: 20,
    itemsDesktop: [1000, 1],
    itemsDesktopSmall: [979, 1],
    itemsTablet: [768, 1],
    pagination: false,
    navigation: false,
    dots: false,
    navigationText: ["", ""],
    slideSpeed: 1000,
    singleItem: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true
};

const CustomerReview = () => {

    return (
        <>
            <OwlCarousel className="owl-carousel mb-5 owl-theme" {...options}>
                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjUtWIFEI5sEj4hQFMn9lqlzBsfNfKfYg6ESxEKeaw8VPlrmuc4=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="Mariya RahmanS" />
                    <div className="media-body">
                        <h5 className="mt-0">Mariya RahmanS</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>Younic Home is undoubtedly an ideal place for students and travelers alike. The hostel is well-organized and exceptionally clean. Each room is tidy and vibrant, with comfortable beds and spacious interiors, making long-term stays pleasant and relaxing.</p>
                    </div>
                </div>
                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjWYul83pl-NbMBaLc-7nXE-XnNbkNPqxNQxm4FErT0NxHCMjGI=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="Eshrat Emroz" />
                    <div className="media-body">
                        <h5 className="mt-0">Eshrat Emroz</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>I feel comfort here. This is my first experience in hostel life. Their stap are very Co-operative. This environment is suitable for study.</p>
                    </div>
                </div>
                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjVMU_iQabN7YVe6Fj4Psw-XtyliAA-wUchMOm5pWGJQ4P6aPsFi=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="Tasnim Jahan" />
                    <div className="media-body">
                        <h5 className="mt-0">Tasnim Jahan</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>Very nice atmosphere and you are very friendly.Service is also very good. Nice place for girls.i love this Place..</p>
                    </div>
                </div>
                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjVn0mC9pwe8R7xeoL-ZAp2Z8Q5TbPZ76cJY_rdBdkUU35kHO7U=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="Rana Ahmed" />
                    <div className="media-body">
                        <h5 className="mt-0">Rana Ahmed</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>It was a awesome experience with Younic Home. Iam satisfied with Younic Home's service</p>
                    </div>
                </div>
                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjUzAielAhK54EW_h-4JvbuKhdDdFXHjrgbZ1TodL_wt5u4uBag=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="..." />
                    <div className="media-body">
                        <h5 className="mt-0">Wahida Binte Zaman</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>Best bachelor accommodation Facility in Dhaka. Suggested for those people who want to stay without any hassle in Dhaka.</p>
                    </div>
                </div>
                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjUgSulan1iPRuEJUn8SZ6K-gqmd0N2vv7_q2UbpXHzhyfYsF028=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="Arzu Easha" />
                    <div className="media-body">
                        <h5 className="mt-0">Arzu Easha</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>It was a great experience there. There service was really good. The location was perfect one.</p>
                    </div>
                </div>

                <div className="media customer_review">
                    <img src="https://lh3.googleusercontent.com/a-/ALV-UjV1ZJ23Y6mdSRr9H1HXftHpzuNucUHUvnALUyVJPON116P8KqGy=s40-c-rp-mo-br100" className="align-self-center  mr-3" alt="..." />
                    <div className="media-body">
                        <h5 className="mt-0">Imran Sharif</h5>
                        <div className='review_rating mb-1'>
                            <span>5</span>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                        <p>One of, if not the coziest bachelor's residences in Dhaka with fully furnished room and complementary meals</p>
                    </div>
                </div>
            </OwlCarousel>
        </>
    );
}

export default CustomerReview;
