import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CountrySelector from '../../component/partials/CountrySelector';
import BootstrapDatePicker from '../../component/partials/BootstrapDatePicker';

const OccupantModal = ({ showOccupantModal, onClose, onSave, seatId, roomId, currentOccupant, errors, processing }) => {
    const [formData, setFormData] = useState({
        seatId: null,
        roomId: null,
        has_booked: false,
        relation: '',
        name: '',
        email: '',
        contact_number: '',
        gender: '',
        dob: '',
        address: '',
        nationality: '',
        identification_type: '',
        identification_number: '',
        has_children: false,
        childrens: [{ id: '', name: '', gender: '', dob: '', relation: '', identification_type: '', identification_number: '' }],
    });

    const handleCountryChange = (country) => {
        setFormData({
            ...formData,
            nationality: country.label || "",
        });
    };

    useEffect(() => {
        if (showOccupantModal) {
            if (currentOccupant?.room_id === roomId && currentOccupant?.seat_id === seatId) {
                setFormData({
                    seatId: seatId || null,
                    roomId: roomId || null,
                    ...currentOccupant,
                });
            } else {
                setFormData({
                    seatId: seatId || null,
                    roomId: roomId || null,
                    id: '',
                    has_booked: false,
                    relation: '',
                    name: '',
                    email: '',
                    contact_number: '',
                    gender: '',
                    dob: '',
                    address: '',
                    nationality: 'Bangladesh',
                    identification_type: '',
                    identification_number: '',
                    has_children: false,
                    childrens: [{ id: '', name: '', gender: '', dob: '', relation: '', identification_type: '', identification_number: '' }],
                });
            }
        }
    }, [showOccupantModal, roomId, seatId, currentOccupant]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, false);
    };

    const getOccupantError = (field) => errors?.occupants?.[field] || '';
    const getChildrensError = (index, field) => errors?.occupants?.['childrens']?.[index]?.[field] || '';

    const handleAddChild = () => {
        setFormData((prevData) => ({
            ...prevData,
            childrens: Array.isArray(prevData.childrens)
                ? [...prevData.childrens, { id: '', name: '', gender: '', dob: '', relation: '', identification_type: '', identification_number: '' }]
                : [{ id: '', name: '', gender: '', dob: '', relation: '', identification_type: '', identification_number: '' }]
        }));
    };

    const handleRemoveChild = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            childrens: prevData.childrens.filter((_, i) => i !== index)
        }));
    };

    const handleChildChange = (index, field, value) => {
        setFormData((prevData) => {
            const newChildrens = [...prevData.childrens];
            newChildrens[index][field] = value;
            return { ...prevData, childrens: newChildrens };
        });
    };

    if (!showOccupantModal) return null;

    return (
        <Modal
            show={showOccupantModal}
            onHide={onClose}
            dialogClassName="modal-dialog modal-dialog-centered modal-lg"
            size={'xl'}
        >
            <form onSubmit={handleSubmit}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                name="has_booked"
                                id="has_booked"
                                className="form-check-input"
                                checked={formData.has_booked}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    relation: '',
                                    has_booked: e.target.checked,
                                })}
                            />
                            <label className="form-check-label col-form-label p-0" htmlFor={`has_booked`}><strong>Is this occupant making this booking?</strong></label>
                        </div>
                    </h5>
                    <button type="button" className="close" onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className={`form-group ${!!formData.has_booked ? 'd-none' : ''}`}>
                        <input
                            type="text"
                            name="relation"
                            value={formData.relation}
                            onChange={handleChange}
                            className="form-control"
                            placeholder='Relation with occupant'
                        />
                        {getOccupantError('relation') && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('relation')}</strong></p>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder='Name'
                                />

                                {getOccupantError('name') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('name')}</strong></p>
                                )}
                            </div>
                        </div>

                        <div className='col-sm-12 col-lg-4'>
                            <div className="form-group">
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    className="form-control"
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                                {getOccupantError('gender') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('gender')}</strong></p>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder='E-mail Address'
                                />
                                {getOccupantError('email') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('email')}</strong></p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <CountrySelector
                                    defaultOptionLabel="Select a country"
                                    value={formData.nationality || ''}
                                    onChange={handleCountryChange}
                                    valueType="label"
                                />

                                {getOccupantError('nationality') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('nationality')}</strong></p>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="contact_number"
                                    value={formData.contact_number}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder='Contact Number'
                                />
                                {getOccupantError('contact_number') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('contact_number')}</strong></p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <select
                                            name="identification_type"
                                            value={formData.identification_type}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={{ 'borderRadius': '3px 0 0 3px' }}
                                        >
                                            <option value="">Identification Type</option>
                                            <option value="Passport">Passport</option>
                                            <option value="National ID">National ID</option>
                                            <option value="Birth Certificate">Birth Certificate</option>
                                        </select>
                                    </div>
                                    <input
                                        type="text"
                                        name="identification_number"
                                        value={formData.identification_number}
                                        onChange={handleChange}
                                        className="form-control"
                                        placeholder='Identification Number'
                                    />
                                </div>
                                {getOccupantError('identification_type') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('identification_type')}</strong></p>
                                )}
                                {getOccupantError('identification_number') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('identification_number')}</strong></p>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder='Address'
                                />
                                {getOccupantError('address') && (
                                    <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('address')}</strong></p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                name="has_children"
                                id="has_children"
                                className="form-check-input"
                                checked={formData.has_children}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    has_children: e.target.checked,
                                    childrens: [{ id: '', name: '', gender: '', dob: '', relation: '', identification_type: '', identification_number: '' }],
                                })}
                            />
                            <label className="form-check-label" htmlFor={`has_children`}>Has Childrens</label>
                        </div>
                        <div className={`form-group ${!formData.has_children ? 'd-none' : ''}`}>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ 'minWidth':'100px' }}>Relation</th>
                                            <th style={{ 'minWidth':'100px' }}>Name</th>
                                            <th style={{ 'minWidth':'100px' }}>Gender</th>
                                            <th>Date of Birth</th>
                                            <th style={{ 'minWidth':'400px' }}>Identification</th>
                                            <th>
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    type="button"
                                                    title="Add more children"
                                                    onClick={handleAddChild}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                </button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(formData.childrens || [{ id: '', name: '', gender: '', dob: '', relation: '', identification_type: '', identification_number: '' }]).map((child, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Relation with child"
                                                        value={child.relation}
                                                        onChange={(e) => handleChildChange(index, 'relation', e.target.value)}
                                                    />
                                                    {getChildrensError(index, 'relation') && (
                                                        <p className='mt-2'><strong className='text-danger mt-3'>{getChildrensError(index, 'relation')}</strong></p>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        placeholder="Name"
                                                        value={child.name}
                                                        onChange={(e) => handleChildChange(index, 'name', e.target.value)}
                                                    />
                                                    {getChildrensError(index, 'name') && (
                                                        <p className='mt-2'><strong className='text-danger mt-3'>{getChildrensError(index, 'name')}</strong></p>
                                                    )}
                                                </td>
                                                <td>
                                                    <select
                                                        className="form-control"
                                                        value={child.gender}
                                                        onChange={(e) => handleChildChange(index, 'gender', e.target.value)}
                                                    >
                                                        <option value="">Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    {getChildrensError(index, 'gender') && (
                                                        <p className='mt-2'><strong className='text-danger mt-3'>{getChildrensError(index, 'gender')}</strong></p>
                                                    )}
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        value={child.dob}
                                                        onChange={(e) => handleChildChange(index, 'dob', e.target.value)}
                                                    />
                                                    {getChildrensError(index, 'dob') && (
                                                        <p className='mt-2'><strong className='text-danger mt-3'>{getChildrensError(index, 'dob')}</strong></p>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="input-group mb-0">
                                                        <div className="input-group-prepend">
                                                            <select
                                                                name="identification_type"
                                                                value={child.identification_type}
                                                                onChange={(e) => handleChildChange(index, 'identification_type', e.target.value)}
                                                                className="form-control"
                                                            >
                                                                <option value="">Type</option>
                                                                <option value="Passport">Passport</option>
                                                                <option value="National ID">National ID</option>
                                                                <option value="Birth Certificate">Birth Certificate</option>
                                                            </select>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="identification_number"
                                                            value={child.identification_number}
                                                            onChange={(e) => handleChildChange(index, 'identification_number', e.target.value)}
                                                            className="form-control"
                                                            placeholder='Number'
                                                        />
                                                    </div>
                                                    {getChildrensError(index, 'identification_type') && (
                                                        <p className='mt-2'><strong className='text-danger mt-3'>{getChildrensError(index, 'identification_type')}</strong></p>
                                                    )}
                                                    {getChildrensError(index, 'identification_number') && (
                                                        <p className='mt-2'><strong className='text-danger mt-3'>{getChildrensError(index, 'identification_number')}</strong></p>
                                                    )}
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        type="button"
                                                        onClick={() => handleRemoveChild(index)}
                                                    >
                                                        <i className="fa fa-minus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {getOccupantError('address') && (
                            <p className='mt-2'><strong className='text-danger mt-3'>{getOccupantError('address')}</strong></p>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={processing}
                    >
                        {processing}
                        {processing ? 'Processing...' : 'Save'}
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                </div>
            </form>
        </Modal>
    );
};

export default OccupantModal;