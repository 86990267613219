import React, { useEffect, useRef } from 'react';
import { useUser } from '../../_context/UserContext';
import Sidebar from './partials/Sidebar';
import WithRouter from '../../_utility/WithRouter';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router';
import api from '../../_utility/AxiosConfig';

const Dashboard = () => {
    const { authUser } = useUser();
    const datatable = useRef(null);
    const tableRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        initializeDatatable();

        return () => {
            if (datatable.current) {
                datatable.current.destroy(); // Destroy the DataTable instance
                datatable.current = null; // Clear the reference
            }
        };
    }, [authUser?.user_id]);

    const initializeDatatable = () => {
        if (tableRef.current) {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy(); // Destroy the existing DataTable
            }

            datatable.current = $(tableRef.current).DataTable({
                responsive: true,
                processing: true,
                serverSide: true,
                ajax: (data, callback, settings) => {
                    api.post(`${process.env.REACT_APP_API_DOMAIN}/api/booking/${authUser?.user_id}/lists`, data)
                        .then((response) => callback(response.data))
                        .catch((error) => {
                            console.error('Error loading data:', error);
                            toast.error('Failed to load data.');
                        });
                },
                columns: getTableColumns(),
                order: [[10, 'desc']],
                columnDefs: getColumnDefs(),
            });

            // Rebind events
            $(tableRef.current).on('click', '.booking_payment', handlePaymentClick);
            $(tableRef.current).on('click', '.view_booking', handleViewClick);
            $(tableRef.current).on('click', '.view_invoice', handleInvoiceViewClick);
            $(tableRef.current).on('click', '.delete_booking', handleDeleteClick);
            $(tableRef.current).on('click', '.edit_booking', handleEditClick);
        }
    };

    const getTableColumns = () => [
        { data: 'id' },
        { data: 'invoice_number' },
        { data: 'apartment_id' },
        { data: 'occupancy_type' },
        { data: 'check_in_date' },
        { data: 'check_out_date' },
        { data: 'number_of_seats' },
        { data: 'total_amount' },
        { data: 'due_amount' },
        { data: 'status' },
        { data: 'created_at' },
        { data: 'options' },
    ];

    const getColumnDefs = () => [
        { targets: [1, 11], className: 'no-sort text-center', orderable: false },
        { targets: [0, 3, 4, 5, 6, 7, 8, 9, 10], className: 'text-center' },
    ];

    const handleDeleteClick = (event) => {
        const id = $(event.currentTarget).attr('booking_id');
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                api.delete(`/api/delete/booking/${id}`)
                    .then((response) => {
                        if (response.data.success) {
                            Swal.fire('Deleted!', response.data.message, 'success');
                            reloadDatatable();
                        }
                    })
                    .catch(() => toast.error('Failed to delete the booking.'));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelled', 'Your booking is safe.', 'error');
            }
        });
    };

    const handleViewClick = (event) => {
        const id = $(event.currentTarget).attr('booking_id');
        navigate(`/view/${id}/booking`);
    };

    const handleInvoiceViewClick = (event) => {
        const id = $(event.currentTarget).attr('booking_id');
        navigate(`/invoice/${id}/view`);
        
    };

    const handlePaymentClick = (event) => {
        const id = $(event.currentTarget).attr('booking_id');
        navigate(`/confirm/${id}/booking`, {
            state: {
                booking_id: id,
            },
        });
    };

    const handleEditClick = (event) => {
        const id = $(event.currentTarget).attr('booking_id');
        navigate(`/booking/edit/${id}`, {
            state: {
                booking_id: id,
            },
        });
    };

    const reloadDatatable = () => {
        $('#bookingsTable').DataTable().ajax.reload();
    };

    return (
        <>
            <section>
                <div className="dashboard mt-0 mb-0">
                    <div className='row'>
                        <div className="col-lg-3 col-sm-12 db-left p-0">
                            <Sidebar />
                        </div>
                        <div className="col-lg-9 col-sm-12 db-cent p-0">
                            <div className="db-cent-1">
                                <p>{authUser?.detail?.name},</p>
                                <h4>Welcome to your dashboard</h4> </div>
                            <div className="db-cent-3">
                                <div className="db-cent-table db-com-table">
                                    <div className="db-title">
                                        <h3><img src="images/icon/dbc5.png" alt="" /> My Bookings</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <table ref={tableRef} id="bookingsTable" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Invoice Number</th>
                                                        <th>Apartment</th>
                                                        <th>Occupancy Type</th>
                                                        <th>Arrival</th>
                                                        <th>Departure</th>
                                                        <th>Seats</th>
                                                        <th>Amount</th>
                                                        <th>Due Amount</th>
                                                        <th>Status</th>
                                                        <th>Created</th>
                                                        <th className="no-sort">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody/>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WithRouter(Dashboard);
