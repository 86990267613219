import React, { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState("login");

    const openModal = (mode) => {
        setModalMode(mode);
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

    return (
        <ModalContext.Provider value={{ showModal, modalMode, setModalMode, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};