import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import WithRouter from '../../_utility/WithRouter';
import api from '../../_utility/AxiosConfig';
import { useNavigate } from 'react-router';

const BookingConfirm = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const booking_id = props.params.booking_id;
    const [bookingInput, setBooking] = useState({
        'booking_id': booking_id,
        'payment_method': '',
        'errors': [],
        'processing': false
    });
    const [loading, setLoading] = useState(true);
    const [bookingInfo, setBookingInfo] = useState([]);

    useEffect(() => {
        api.post(`/api/booking/${booking_id}/details`, bookingInfo)
            .then(res => {
                setBookingInfo(res.data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [booking_id]);

    const handleChange = (e) => {
        setBooking({ ...bookingInput, [e.target.name]: e.target.value });
    }

    const handlePayment = async () => {
        setBooking({ ...bookingInput, errors: [], processing: true });
        try {
            api.post(`/api/bkash/create-payment`, {
                booking_id: booking_id,
            }).then(res => {
                setBooking({ ...bookingInput, processing: false });
                if (res.data && res.data.bkashURL) {
                    window.location.href = res.data.bkashURL;
                } else {
                    setError("");
                    toast.error("Payment URL not received.");
                }
            }).catch((error) => {
                setBooking({ ...bookingInput, processing: false });
                const errorMessage = error.response?.data?.message || 'Failed to initiate payment.';
                toast.error(errorMessage);
                setTimeout(() => {
                    navigate(`/booking/edit/${booking_id}`, {
                        state: {
                            booking_id: booking_id,
                        },
                    });
                }, 500);
            });
        }
        catch (err) {
            alert();
            setBooking({ ...bookingInput, processing: false });
            const errorMessage = (err.response?.data?.message || err.response?.data?.error) || "Something went wrong.";
            toast.error(errorMessage); // Display error message
        }
    };

    return (
        <>
            <ToastContainer />
            <section>
                <div className="inn-body-section">
                    <div className="container">
                        <div className="page-head">
                            <h2>Confirm Your Booking</h2>
                            <div className="head-title">
                                <div className="hl-1"></div>
                                <div className="hl-2"></div>
                                <div className="hl-3"></div>
                            </div>
                            <p>Complete your booking by making a secure payment. Follow the instructions to finalize your reservation and get instant confirmation.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-8 offset-md-2'>
                                {error && <p style={{ color: "red" }}>{error}</p>}


                                {!loading ? (
                                    <>
                                        <div className='shadow_card'>
                                            <div className='form-group mb-3'>
                                                <div className='form-group'>
                                                    <strong>Branch: </strong>
                                                    {bookingInfo?.apartment?.branch?.name}
                                                </div>

                                                <div className='form-group'>
                                                    <strong>Address: </strong>
                                                    {bookingInfo?.apartment?.address}
                                                </div>

                                                <div className='form-group'>
                                                    <strong>Occupancy: </strong>
                                                    {bookingInfo?.occupancy_type}
                                                </div>
                                                <div className='form-group'>
                                                    <strong>Dates: </strong>
                                                    {bookingInfo?.occupancyType === 'Permanent' ? (
                                                        <>
                                                            {bookingInfo?.check_in_date ? bookingInfo?.check_in_date : 'Check In'}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span>
                                                                {bookingInfo?.check_in_date ? bookingInfo?.check_in_date : 'Check In'} - {bookingInfo?.check_in_date ? bookingInfo?.check_in_date : 'Check Out'}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>

                                                <div className='form-group'>
                                                    {bookingInfo?.occupancyType === 'Permanent' ? (
                                                        <>
                                                            <strong>Seats</strong>
                                                            <span>{bookingInfo?.number_of_seats}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>
                                                                {bookingInfo?.total_adults > 0 && <span className='text-black' style={{ 'fontSize': '16px' }}>{bookingInfo?.total_adults} Adult{bookingInfo?.total_adults > 1 ? 's' : ''} </span>}
                                                                {bookingInfo?.total_children > 0 && <span className='text-black' style={{ 'fontSize': '16px' }}>{bookingInfo?.total_children} Child{bookingInfo?.total_children > 1 ? 'ren' : ''} </span>}
                                                                {bookingInfo?.number_of_seats > 0 && <span className='text-black' style={{ 'fontSize': '16px' }}>{bookingInfo?.number_of_seats} Seat{bookingInfo?.number_of_seats > 1 ? 's' : ''} </span>}
                                                            </strong>
                                                        </>
                                                    )}
                                                </div>

                                                <div className='d-flex justify-content-between w-100'>
                                                    <div className='form-group'>
                                                        <strong>Total (BDT): </strong>
                                                        <strong>৳{bookingInfo?.total_amount}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-field">
                                                    <select
                                                        name="payment_method"
                                                        value={bookingInput.payment_method}
                                                        onChange={handleChange}
                                                        className="form-control"
                                                    >
                                                        <option value="">Select Payment Method</option>
                                                        <option value="Bkash">Bkash</option>
                                                    </select>
                                                </div>
                                                {bookingInput.errors && bookingInput.errors.payment_method && (
                                                    <p className='mt-2'><strong className='text-danger mt-3'>{bookingInput.errors.payment_method}</strong></p>
                                                )}
                                            </div>

                                            <button
                                                type="submit"
                                                className="btn btn-success btn-lg btn-block"
                                                disabled={bookingInput.processing}
                                                onClick={handlePayment}
                                            >
                                                {bookingInput.processing ? 'Processing...' : 'Confirm Booking'}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="col-md-12 shadow_card">
                                                <div className="ph-item border-0 p-0 m-0">
                                                    <div>
                                                        <div className="ph-row">
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-2"></div>
                                                            <div className="ph-col-10 empty"></div>
                                                            <div className="ph-col-8"></div>
                                                            <div className="ph-col-4 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                    <div className="ph-col-12">
                                                        <div className="ph-row">
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-10 big"></div>
                                                            <div className="ph-col-2 empty big"></div>
                                                            <div className="ph-col-4"></div>
                                                            <div className="ph-col-8 empty"></div>
                                                            <div className="ph-col-6"></div>
                                                            <div className="ph-col-6 empty"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                            <div className="ph-col-12"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WithRouter(BookingConfirm);
