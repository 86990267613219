import React from 'react';

const Faq = () => {
    return (
        <>
            <div className="faq-title">
                <h2>F.A.Q</h2>
                <p>Frequently Asked Questions</p>
            </div>
            <ul className="faq-list">
                {/* General Questions */}
                <li data-aos="fade-up" data-aos-delay="100">
                    <a data-toggle="collapse" className="collapsed" href="#faq1">General Questions <i className="fa fa-arrow-up"></i></a>
                    <div id="faq1" className="collapse" data-parent=".faq-list">
                        <p className='mb-2'><strong>1. What is Younic Home?</strong></p>
                        <p className='mb-2'>Younic Home is a premium accommodation service offering comfortable and secure living spaces with modern amenities for students and working professionals.</p>
                        
                        <p className='mb-2'><strong>2. Where are Younic Home’s branches located?</strong></p>
                        <p className='mb-2'>Younic Home operates in three locations:</p>
                        <ul>
                            <li className='pb-0 pt-0'>Dhanmondi</li>
                            <li className='pb-0 pt-0'>Ashulia</li>
                            <li className='pb-0 pt-0'>Uttara</li>
                        </ul>
                        <p className='mb-2'>We have a total of five branches:</p>
                        <ul>
                            <li className='pb-0 pt-0'>Three Female Branches</li>
                            <li className='pb-0 pt-0'>Two Male Branches</li>
                        </ul>
                        
                        <p className='mb-2'><strong>3. Who can stay at Younic Home?</strong></p>
                        <p className='mb-2'>Younic Home is open to students, working professionals, and individuals looking for quality, secure accommodation.</p>
                    </div>
                </li>
                
                {/* Facilities & Services */}
                <li data-aos="fade-up" data-aos-delay="200">
                    <a data-toggle="collapse" href="#faq2">Facilities & Services <i className="fa fa-arrow-up"></i></a>
                    <div id="faq2" className="collapse" data-parent=".faq-list">
                        <p className='mb-2'><strong>4. What facilities does Younic Home offer?</strong></p>
                        <ul>
                            <li className='pb-0 pt-0'>Bunk bed accommodations</li>
                            <li className='pb-0 pt-0'>24/7 generator backup</li>
                            <li className='pb-0 pt-0'>Housekeeping services</li>
                            <li className='pb-0 pt-0'>24-hour CCTV security</li>
                            <li className='pb-0 pt-0'>High-speed WiFi</li>
                            <li className='pb-0 pt-0'>Shared kitchen and dining facilities</li>
                            <li className='pb-0 pt-0'>Daily meal services</li>
                        </ul>
                        
                        <p className='mb-2'><strong>5. Are meals provided at Younic Home?</strong></p>
                        <p className='mb-2'>Yes, we provide daily meal services with nutritious and hygienic food options. Residents can also use the shared kitchen if they prefer to cook.</p>
                        
                        <p className='mb-2'><strong>6. Is housekeeping included in the rent?</strong></p>
                        <p className='mb-2'>Yes, housekeeping services are included to maintain cleanliness and hygiene.</p>
                    </div>
                </li>
                
                {/* Security & Safety */}
                <li data-aos="fade-up" data-aos-delay="300">
                    <a data-toggle="collapse" href="#faq3">Security & Safety <i className="fa fa-arrow-up"></i></a>
                    <div id="faq3" className="collapse" data-parent=".faq-list">
                        <p className='mb-2'><strong>7. How secure is Younic Home?</strong></p>
                        <p className='mb-2'>We prioritize security with 24-hour CCTV surveillance, biometric access control, and on-site staff for additional safety.</p>
                        
                        <p className='mb-2'><strong>8. Are male and female accommodations separate?</strong></p>
                        <p className='mb-2'>Yes, we have separate branches for male and female residents to ensure privacy and security.</p>
                    </div>
                </li>
                
                {/* Booking & Rent */}
                <li data-aos="fade-up" data-aos-delay="400">
                    <a data-toggle="collapse" href="#faq4">Booking & Rent <i className="fa fa-arrow-up"></i></a>
                    <div id="faq4" className="collapse" data-parent=".faq-list">
                        <p className='mb-2'><strong>9. How can I book a room at Younic Home?</strong></p>
                        <p className='mb-2'>You can book a room by contacting us via phone, email, or visiting our website.</p>
                        
                        <p className='mb-2'><strong>10. What is the rent structure?</strong></p>
                        <p className='mb-2'>Rent varies based on location and room type. Please contact us for detailed pricing information.</p>
                        
                        <p className='mb-2'><strong>11. Is there a security deposit required?</strong></p>
                        <p className='mb-2'>Yes, a refundable security deposit is required at the time of booking.</p>
                        
                        <p className='mb-2'><strong>12. What payment methods are accepted?</strong></p>
                        <p className='mb-2'>We accept bank transfers, mobile banking, and cash payments.</p>
                    </div>
                </li>
                
                {/* Additional Services */}
                <li data-aos="fade-up" data-aos-delay="500">
                    <a data-toggle="collapse" href="#faq5">Additional Services <i className="fa fa-arrow-up"></i></a>
                    <div id="faq5" className="collapse" data-parent=".faq-list">
                        <p className='mb-2'><strong>13. Does Younic Home offer special packages for mothers?</strong></p>
                        <p className='mb-2'>Yes, our "Care & Comfort for Mothers" package is available at the Uttara Female Branch.</p>
                        
                        <p className='mb-2'><strong>14. Can I bring my own furniture?</strong></p>
                        <p className='mb-2'>Younic Home provides fully furnished accommodations, so additional furniture is not required.</p>
                        
                        <p className='mb-2'><strong>15. Is there a curfew or visitor policy?</strong></p>
                        <p className='mb-2'>For security reasons, curfew and visitor policies vary by branch. Contact your branch manager for details.</p>
                    </div>
                </li>
                
                {/* Support & Contact */}
                <li data-aos="fade-up" data-aos-delay="600">
                    <a data-toggle="collapse" href="#faq6">Support & Contact <i className="fa fa-arrow-up"></i></a>
                    <div id="faq6" className="collapse" data-parent=".faq-list">
                        <p className='mb-2'><strong>16. How can I get support if I have an issue?</strong></p>
                        <p className='mb-2'>You can reach out to our support team via phone, WhatsApp, or email.</p>
                        
                        <p className='mb-2'><strong>17. What is Younic Home’s contact information?</strong></p>
                        <p className='mb-2'>📞 Phone: 013250151915 / 01711704729</p>
                        <p className='mb-2'>📧 Email: info@younichostel.com</p>
                    </div>
                </li>
            </ul>
        </>
    );
};

export default Faq;
