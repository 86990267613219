import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useBranches from '../../data/useBranches';
import SessionHelper from '../../session/SessionHelper';
import { useModal } from '../../_context/ModalContext';

const Footer = () => {
    const { openModal } = useModal();
    const branches = useBranches();
    const navigate = useNavigate();

    const handleFilter = (e, branch_id, keyword, occupancy_type) => {
        e.preventDefault();

        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries({
                    occupancy_type: occupancy_type,
                    keyword: keyword,
                    branch_id: branch_id
                }).filter(([key, value]) => value !== '' && value !== 0 && value !== null && value !== undefined)
            )
        );

        navigate({
            pathname: '/',
            search: `?${queryParams.toString()}`,
        });
    };

    return (
        <>
            <footer className="site-footer clearfix pb-5">
                <div className="sidebar-container">
                    <div className="sidebar-inner">
                        <div className="widget-area clearfix">
                            <div className="widget widget_azh_widget">
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-3 foot-logo"> <img src="/assets/images/logo1.png" alt="logo" />
                                                <p className="hasimg">A dormitory is an establishment that provides paid lodging on a short-term and long-term basis.</p>
                                                <p className="hasimg">Facilities provided may range from a modest-quality.</p>
                                                {/* <h4>Subscribe Now</h4>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="E-mail Address" aria-label="E-mail Address" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-danger" type="button"><i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <h4>Support &amp; Help</h4>
                                                <ul className="d-flex">
                                                    <div>
                                                        <li><Link to='/'>Home</Link></li>
                                                        <li><Link to='/about-us'>About Us</Link></li>
                                                        <li><Link to='/services'>Services</Link></li>
                                                        <li><Link to='/contact-us'>Contact Us</Link></li>
                                                    </div>
                                                    <div>
                                                        <li><Link to='/events'>Events</Link></li>
                                                        {SessionHelper.GetAuthSession() && (
                                                            <li><Link to='/dashboard'>My Account</Link></li>
                                                        )}

                                                        {!SessionHelper.GetAuthSession() && (
                                                            <>
                                                                <li><Link onClick={() => openModal('register')}>Register</Link></li>
                                                                <li><Link onClick={() => openModal('login')}>Log In</Link></li>
                                                            </>
                                                        )}
                                                        <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                                        <li><Link to='/terms-of-service'>Terms & Conditions</Link></li>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <h4>Branches</h4>
                                                <ul >
                                                    {branches && branches.length > 0 ? (
                                                        branches.map((branch, index) => (
                                                            <li
                                                                key={index}
                                                                onClick={(e) => handleFilter(e, branch.id, branch.name, '')}
                                                            >
                                                                <a href="javascripts:void(0)">
                                                                    {branch.name}
                                                                </a>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No branches available.</li> // Message when no branches are present
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-3">
                                                <h4>Address</h4>
                                                <p>House-20, Road-5, Sector-5, Uttara, Dhaka-1230.</p>
                                                <p className='text-primary'><i className="fa fa-phone"></i> +8801325-051915, +8801711-704729</p>
                                                <div className="foot-social">
                                                    <ul>
                                                        <li><a href="https://www.facebook.com/YounicHome" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://bd.linkedin.com/company/younic-home" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://www.instagram.com/younichome" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://www.youtube.com/@younichome2023" target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i></a> </li>
                                                        <li><a href="https://www.tiktok.com/@younic_home" target='_blank' title='Tiktok'><i className="fa fa-tiktok" aria-hidden="true"></i></a> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="foot-sec2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                <h4>Payment Options</h4>
                                                <p className="hasimg"> <img src="/assets/images/payment.png" alt="payment" /> </p>
                                            </div>
                                            <div className="col-sm-12 col-md-8 col-lg-4">
                                                <h4>Subscribe Now</h4>
                                                <form>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Enter your email address" aria-label="Enter your email address" />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-danger" type="button">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-4 foot-social">
                                                <h4>Follow with us</h4>
                                                <ul>
                                                    <li><a href="https://www.facebook.com/YounicHome" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> </li>
                                                    <li><a href="https://bd.linkedin.com/company/younic-home" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a> </li>
                                                    <li><a href="https://www.instagram.com/younichome" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a> </li>
                                                    <li><a href="https://www.youtube.com/@younichome2023" target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i></a> </li>
                                                    <li><a href="https://www.tiktok.com/@younic_home" target='_blank' title='Tiktok'><i className="fa fa-tiktok" aria-hidden="true"></i></a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="copy">
                <div className="container pl-0 pr-0">
                    <p >Copyright © {new Date().getFullYear()} Younic Hostel. All Rights Reserved. Develop by Younic Dev.</p>
                </div>
            </div>

            <div className="hom-footer-section">
                <div className="container-lg">
                    <div className="row">
                        <div className='col-sm-12'>
                            <div className="foot-com foot-1">
                                <ul>
                                    <li><a href="https://www.facebook.com/YounicHome" target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a> </li>
                                    <li><a href="https://bd.linkedin.com/company/younic-home" target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a> </li>
                                    <li><a href="https://www.instagram.com/younichome" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a> </li>
                                    <li><a href="https://www.youtube.com/@younichome2023" target='_blank'><i className="fa fa-youtube" aria-hidden="true"></i></a> </li>
                                    <li><a href="https://www.tiktok.com/@younic_home" target='_blank' title='Tiktok'><i className="fa fa-tiktok" aria-hidden="true"></i></a> </li>
                                </ul>
                            </div>
                            <div className="foot-com foot-2">
                                <h5>Helpline: +8801325-051915, +8801711-704729</h5> </div>
                            <div className="foot-com foot-3">
                                <a href='#'
                                    className="waves-effect waves-light"
                                    onClick={(e) => handleFilter(e, '', '', 'Guest')}
                                >
                                    Guest Booking
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
