import React, {useState, useEffect} from 'react';
import axios from "axios";
import api from '../../../_utility/AxiosConfig';

function SocialLogin({setAuthModalMode}) {
    const [googleLoginUrl, setGoogleLoginUrl] = useState(null);
    const [facebookLoginUrl, setFacebookLoginUrl] = useState(null);
    const [instagramLoginUrl, setInstagramLoginUrl] = useState(null);

    useEffect(() => {
        if (!facebookLoginUrl || !instagramLoginUrl || !googleLoginUrl) {
            Promise.all([
                api.post('/api/auth/facebook'),
                api.post('/api/auth/instagram'),
                api.post('/api/auth/google')
            ])
            .then(([facebookRes, instagramRes, googleRes]) => {
                setFacebookLoginUrl(facebookRes.data.url);
                setInstagramLoginUrl(instagramRes.data.url);
                setGoogleLoginUrl(googleRes.data.url);
            })
            .catch((error) => {
                console.error('Error fetching social login URLs:', error);
            });
        }
    }, [facebookLoginUrl, instagramLoginUrl, googleLoginUrl]);

    return (
        <>
            {/* <li><a href={facebookLoginUrl || "#"}><i className="fa fa-facebook"></i> Facebook</a></li> */}
            {/* <li><a href={instagramLoginUrl || "#"}><i className="fa fa-instagram"></i> Instagram</a></li> */}
            {/* <li><a href={googleLoginUrl || "#"}><i className="fa fa-google"></i> Google</a></li> */}
            <ul class="nav nav-pills nav-justified mb-3">
                <li class="nav-item w-50 mr-3" role="presentation">
                    <a class="btn btn-primary text-white w-100" href={facebookLoginUrl || "#"}><i className="fa fa-facebook"></i> Facebook</a>
                </li>
                <li class="nav-item w-50" role="presentation">
                    <a class="btn btn-danger text-white w-100" href={googleLoginUrl || "#"}><i className="fa fa-google"></i> Google</a>
                </li>
            </ul>
        </>
    );
}

export default SocialLogin;