import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../../_context/UserContext';

const Sidebar = () => {
    const { logout, authUser } = useUser();
    return (
        <>
            <div className="db-left-1">
                <h4>{authUser?.detail?.name}</h4>
                <p>{authUser?.detail?.address}</p>
            </div>
            <div className="db-left-2">
                <ul>
                    <li>
                        <Link className="dropdown-item" to="/dashboard">My Account</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/profile">Profile</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="/change/password">Change Password</Link>
                    </li>
                    <li>
                        <Link className="dropdown-item" to="" onClick={logout}>Logout</Link>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Sidebar;
